import {
  Box,
  HStack,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { divide } from '@trifence/utilities';
import { useGetBusinessTransactionsSummaryQuery } from '../graphql/hooks/queries/getBusinessTransactionsSummary';

type UserTransactionsSummary = any;
type DeviceTransactionsSummary = any;

type Props = {
  businessId: string;
  createdAfter: number;
  createdBefore: number;
  title: string;
  onClose?: () => void;
};

function fullName(user: any) {
  if (user.name && user.name.first && user.name.last) {
    return `${user.name.first} ${user.name.last}`;
  } else {
    return '';
  }
}

export function TransactionsSummaryModal(props: Props) {
  const { businessId, createdAfter, createdBefore, title, onClose } = props;
  const { t } = useTranslation('transactionsSummaryModal');
  const getBusinessTransactionsSummaryQuery =
    useGetBusinessTransactionsSummaryQuery({
      variables: {
        businessId,
        createdAfter,
        createdBefore,
      },
      pollInterval: 1000,
    });

  const { summary, usersTransactionsSummaries } = {
    summary: getBusinessTransactionsSummaryQuery.data
      ?.businessTransactionsSummary.summary ?? { credit: 0, debit: 0 },
    usersTransactionsSummaries:
      getBusinessTransactionsSummaryQuery.data?.businessTransactionsSummary
        .usersTransactionsSummaries ?? [],
  };

  function handleClose() {
    onClose?.();
  }

  if (getBusinessTransactionsSummaryQuery.loading) {
    return null;
  }

  return (
    <Modal isCentered={false} isOpen={true} onClose={handleClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Table
            size="sm"
            variant="simple"
            colorScheme="brand"
            paddingInline={{ base: 0, md: 6 }}
          >
            <Thead>
              <Tr>
                <Th></Th>
                <Th textAlign="right">{t('payments')}</Th>
                <Th textAlign="right">{t('top-ups')}</Th>
                <Th textAlign="right">{t('balance')}</Th>
              </Tr>
            </Thead>

            <Tbody>
              {usersTransactionsSummaries.map(
                (userTransactionsSummary: UserTransactionsSummary) => (
                  <UserTransactionsSummaryRows
                    data={userTransactionsSummary}
                    key={
                      userTransactionsSummary.user._id ??
                      fullName(userTransactionsSummary.user)
                    }
                  />
                ),
              )}

              <Tr backgroundColor="brand.600" key="total">
                <Td fontWeight="bold">{t('total')}</Td>
                <Td isNumeric={true} fontWeight="bold">
                  {formatNumber(divide(summary.credit, 100))}
                </Td>
                <Td isNumeric={true} fontWeight="bold">
                  {formatNumber(divide(summary.debit, 100))}
                </Td>
                <Td isNumeric={true} fontWeight="bold">
                  {formatNumber(divide(summary.credit - summary.debit, 100))}
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </ModalBody>

        <ModalFooter>
          <HStack>
            <Button onClick={onClose}>{t('cancel')}</Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function UserTransactionsSummaryRows(props: { data: UserTransactionsSummary }) {
  const {
    data: { user, summary, devicesTransactionsSummaries },
  } = props;
  const { t } = useTranslation('transactionsSummaryModal');

  return (
    <>
      {devicesTransactionsSummaries.map(
        (deviceTransactionsSummary: DeviceTransactionsSummary) => (
          <DeviceTransactionsSummaryRow
            data={deviceTransactionsSummary}
            key={
              deviceTransactionsSummary.device._id ??
              `${deviceTransactionsSummary.device.color} ${deviceTransactionsSummary.device.name}`
            }
          />
        ),
      )}

      <Tr backgroundColor="gray.600" key={fullName(user)}>
        <Td
          fontWeight="bold"
          opacity={user.deletedAt ? 0.7 : 1}
          textDecoration={user.deletedAt ? 'line-through' : undefined}
        >
          {[t('subtotal'), user.name.first, user.name.last].join(' ')}
        </Td>
        <Td isNumeric={true} fontWeight="bold">
          {formatNumber(divide(summary.credit, 100))}
        </Td>
        <Td isNumeric={true} fontWeight="bold">
          {formatNumber(divide(summary.debit, 100))}
        </Td>
        <Td isNumeric={true} fontWeight="bold">
          {formatNumber(divide(summary.credit - summary.debit, 100))}
        </Td>
      </Tr>
    </>
  );
}

function DeviceTransactionsSummaryRow(props: {
  data: DeviceTransactionsSummary;
}) {
  const {
    data: { device, summary },
  } = props;

  return (
    <Tr>
      <Td>
        <HStack>
          <Box boxSize="1em" backgroundColor={device.color} rounded="full" />

          <Text
            opacity={device.deletedAt ? 0.7 : 1}
            textDecoration={device.deletedAt ? 'line-through' : undefined}
          >
            {device.name}
          </Text>
        </HStack>
      </Td>

      <Td isNumeric={true}>{formatNumber(divide(summary.credit, 100))}</Td>
      <Td isNumeric={true}>{formatNumber(divide(summary.debit, 100))}</Td>
      <Td isNumeric={true}>
        {formatNumber(divide(summary.credit - summary.debit, 100))}
      </Td>
    </Tr>
  );
}

function formatNumber(value: number): string {
  return value.toFixed(2);
}
