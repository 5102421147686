import { Stack, Heading, Button, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

function unique(value: string, index: number, self: string[]) {
  return self.indexOf(value) === index;
}

function valid(value: string) {
  return value && value.length > 10;
}

function handleRecoverClick() {
  let all: string[] = [];
  all.push(localStorage.getItem('deviceId') ?? '');
  if (localStorage.getItem('deviceIds')) {
    try {
      const ids = JSON.parse(localStorage.getItem('deviceIds') ?? '[]');
      for (const id of ids) {
        if (typeof id === 'string') {
          all.push(id);
        }
      }
    } catch (error) {}
  }
  if (localStorage.getItem('bearerToken')) {
    try {
      const ids = JSON.parse(localStorage.getItem('bearerToken') ?? '{}');
      for (const id of Object.entries(ids)) {
        if (typeof id === 'string') {
          all.push(id);
        }
      }
    } catch (error) {}
  }
  const good = all.filter(unique).filter(valid);
  // Set deviceId only when we have a valid deviceIds list
  // (triggers operations in other tabs)
  if (good.length === 0) {
    delete localStorage['deviceIds'];
    delete localStorage['deviceId'];
  } else {
    localStorage.setItem('deviceIds', JSON.stringify(good));
    localStorage.setItem('deviceId', good[0]);
  }
  window.location.assign('/');
}

export function Recover() {
  const { t } = useTranslation('landing');

  return (
    <Stack marginBottom={16} padding={{ base: 4, md: 8 }} spacing={8}>
      <Heading as="h1" textAlign={{ base: 'center', md: 'left' }} size="2xl">
        {t('recover.heading')}
      </Heading>

      <Text size="2xl" fontSize="2xl">
        {t('recover.explanation')}
      </Text>

      <Button colorScheme="brand" size="lg" onClick={handleRecoverClick}>
        {t('recover.button')}
      </Button>
    </Stack>
  );
}
