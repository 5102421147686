import { gql } from '@apollo/client';
import { BUSINESS_DATA_FRAGMENT } from '../fragments/businessData';

export const GET_BUSINESS_QUERY = gql`
  query getBusiness(
    $_id: String!
    $transactionsSkip: Int
    $transactionsLimit: Int
    $usersSkip: Int
    $usersLimit: Int
  ) {
    business: getBusiness(_id: $_id) {
      ...BusinessData
    }
  }

  ${BUSINESS_DATA_FRAGMENT}
`;
