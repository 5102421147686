import { gql } from '@apollo/client';
import { TRANSACTION_DATA_FRAGMENT } from '../fragments/transactionData';

export const CANCEL_TRANSACTION_MUTATION = gql`
  mutation cancelTransaction($input: CancelTransactionMutationInput!) {
    cancelTransaction(input: $input) {
      transaction {
        ...TransactionData
      }
    }
  }

  ${TRANSACTION_DATA_FRAGMENT}
`;
