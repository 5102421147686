import { chakra, TextProps } from '@chakra-ui/react';
import { cx } from '@chakra-ui/utils';
import { keyframes } from '@chakra-ui/system';
import { useStorage } from '@companyon/hooks';

type Props = TextProps;

const coloring = keyframes({
  '0%': { color: 'white', transform: 'rotate(0deg)' },
  '20%': { color: '#009ae6', transform: 'rotate(72deg)' },
  '40%': { color: 'black', transform: 'rotate(144deg)' },
  '60%': { color: 'yellow', transform: 'rotate(216deg)' },
  '80%': { color: 'red', transform: 'rotate(288deg)' },
  '100%': { color: 'white', transform: 'rotate(360deg)' },
});

export function TestNotice(props: Props) {
  const { children, ...rest } = props;
  const [{ demoMode }] = useStorage();
  const animation = { animation: `${coloring} 2s linear infinite` };
  const className = cx('companyon-testnotice');

  if (process.env.REACT_APP_ENV === 'staging' && !demoMode) {
    return (
      <chakra.div
        __css={animation}
        className={className}
        fontSize="6xl"
        color="white"
        onClick={(e) => {
          const spinner = document.querySelector<HTMLDivElement>(
            '.' + className,
          );
          if (spinner) {
            spinner.style.animationIterationCount = '1';
          }
        }}
        {...rest}
      >
        {children ?? 'Test'}
      </chakra.div>
    );
  } else {
    return null;
  }
}
