import { gql } from '@apollo/client';

export const SET_DEVICE_BEARER_TOKEN_MUTATION = gql`
  mutation setDeviceBearerToken {
    setDeviceBearerToken {
      deviceId
      bearerToken
    }
  }
`;
