import { memo, useState, useMemo } from 'react';
import {
  Box,
  Stack,
  SimpleGrid,
  Heading,
  Stat,
  StatLabel,
  StatNumber,
  Skeleton,
  useBreakpoint,
} from '@chakra-ui/react';
import { ButtonList, FAB } from '@companyon/components';
import { IoIosCard } from 'react-icons/io';
import { FaCheckCircle, FaUserCircle, FaMoneyBill } from 'react-icons/fa';
import { RiQrCodeLine } from 'react-icons/ri';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formatCurrency, formatWord, divide, match } from '@trifence/utilities';
import { ExplanationDrawer } from '../components/ExplanationDrawer';
import { useTimeOfDay } from '../hooks/useTimeOfDay';
import { useGetDeviceQuery } from '../graphql/hooks/queries/getDevice';
import { useGetBusinessQuery } from '../graphql/hooks/queries/getBusiness';
import { useStorage } from '@companyon/hooks';

export function Dashboard() {
  return (
    <Stack marginBottom={16} padding={{ base: 4, md: 8 }} spacing={8}>
      <Greeting />
      <Overview />
      <Actions />
    </Stack>
  );
}

const Greeting = memo(() => {
  const size = useBreakpoint();
  const { t } = useTranslation('dashboard');
  const timeOfDay = useTimeOfDay();

  const text = useMemo(() => {
    return t(['greeting', timeOfDay].join('.'));
  }, [timeOfDay, t]);

  if (size === 'base') {
    return null;
  }

  return (
    <Heading as="h1" size="2xl">
      {text}
    </Heading>
  );
});

Greeting.displayName = 'Greeting';

const Overview = memo(() => {
  const [{ deviceId }] = useStorage();
  const { t } = useTranslation('dashboard');

  const getDeviceQuery = useGetDeviceQuery({
    variables: {
      _id: deviceId,
    },
  });

  const getBusinessQuery = useGetBusinessQuery({
    skip: !getDeviceQuery.data?.device.owner.employerId,
    variables: {
      _id: getDeviceQuery.data?.device.owner.employerId ?? '',
    },
  });

  const [explanationTopic, setExplanationTopic] = useState<
    'BALANCE' | 'AVAILABLE' | null
  >(null);

  const {
    canViewBusinessBalance,
    canViewBusinessTransactionCount,
    canViewBusinessUserCount,
  } = {
    canViewBusinessBalance:
      getDeviceQuery.data?.device.permissions.viewBusinessBalance,
    canViewBusinessTransactionCount:
      getDeviceQuery.data?.device.permissions.viewBusinessTransactionCount,
    canViewBusinessUserCount:
      getDeviceQuery.data?.device.permissions.viewBusinessUserCount,
  };

  const { balance, overdraftLimit, transactionsCount, devicesCount } = {
    balance: getBusinessQuery.data?.business.balance ?? 0,
    overdraftLimit: getBusinessQuery.data?.business.overdraftLimit?.amount ?? 0,
    transactionsCount: getBusinessQuery.data?.business.transactionsCount ?? 0,
    devicesCount: getBusinessQuery.data?.business.devicesCount ?? 0,
  };

  const { formattedBalance, formattedAvailable } = {
    formattedBalance: formatCurrency()(divide(balance, 100)),
    formattedAvailable: formatCurrency()(divide(balance + overdraftLimit, 100)),
  };

  const statProps = {
    cursor: 'pointer',
    _hover: {
      opacity: 0.9,
    },
  };

  const stats = useMemo(() => {
    const iconProps = {
      size: 32,
    };

    return [
      {
        id: 'BALANCE',
        label: t('balance'),
        value: formattedBalance,
        icon: <FaMoneyBill {...iconProps} />,
        color: 'green',
        onClick: () => {
          openExplanationDrawer('BALANCE');
        },
      },
      {
        id: 'AVAILABLE',
        label: t('available'),
        value: formattedAvailable,
        icon: <FaCheckCircle {...iconProps} />,
        color: 'purple',
        onClick: () => {
          openExplanationDrawer('AVAILABLE');
        },
      },
      {
        id: 'TRANSACTIONS',
        label: formatWord(
          t('transaction.singular'),
          t('transaction.plural'),
        )(transactionsCount),
        value: transactionsCount,
        icon: <IoIosCard {...iconProps} />,
        color: 'blue',
        linksTo: '/transactions',
      },
      {
        id: 'USERS',
        label: formatWord(
          t('device.singular'),
          t('device.plural'),
        )(devicesCount),
        value: devicesCount,
        icon: <FaUserCircle {...iconProps} />,
        color: 'red',
        linksTo: '/users',
      },
    ].filter((stat) => {
      return match(stat.id)
        .with('BALANCE', () => canViewBusinessBalance)
        .with('AVAILABLE', () => canViewBusinessBalance)
        .with('TRANSACTIONS', () => canViewBusinessTransactionCount)
        .with('USERS', () => canViewBusinessUserCount)
        .otherwise(() => true);
    });
  }, [
    canViewBusinessBalance,
    canViewBusinessTransactionCount,
    canViewBusinessUserCount,
    formattedBalance,
    formattedAvailable,
    transactionsCount,
    devicesCount,
    t,
  ]);

  function openExplanationDrawer(explanationTopic: 'BALANCE' | 'AVAILABLE') {
    setExplanationTopic(explanationTopic);
  }

  function closeExplanationDrawer() {
    setExplanationTopic(null);
  }

  if (getDeviceQuery.loading || stats.length === 0) {
    return null;
  }

  return (
    <>
      <Stack spacing={4}>
        <Heading as="h3" size="lg">
          {t('overview')}
        </Heading>

        <SimpleGrid minChildWidth="15em" spacing={4}>
          {(() => {
            if (getBusinessQuery.loading) {
              return stats.map((stat) => (
                <Skeleton
                  height="9em"
                  padding={4}
                  borderWidth={1}
                  borderRadius="lg"
                  startColor={`${stat.color}.500`}
                  endColor={`${stat.color}.700`}
                  key={stat.label}
                />
              ));
            }

            return stats.map((stat) => (
              <Stat
                fontFamily="Montserrat"
                as={stat.linksTo ? Link : Stat}
                height="9em"
                padding={4}
                borderWidth={1}
                borderRadius="lg"
                backgroundColor={`${stat.color}.500`}
                to={stat.linksTo}
                onClick={stat.onClick}
                key={stat.id}
                {...statProps}
              >
                <Box paddingBottom={2}>{stat.icon}</Box>
                <StatLabel fontSize="md">{stat.label}</StatLabel>
                <StatNumber>{stat.value}</StatNumber>
              </Stat>
            ));
          })()}
        </SimpleGrid>
      </Stack>

      <ExplanationDrawer
        topic={explanationTopic}
        onClose={closeExplanationDrawer}
      />
    </>
  );
});

Overview.displayName = 'Overview';

const Actions = memo(() => {
  const history = useHistory();
  const { t } = useTranslation('dashboard');

  function handleScanClick() {
    history.push('/scan');
  }

  const scanCardButton = {
    as: Link,
    children: t('scanCard'),
    leftIcon: <RiQrCodeLine size={20} />,
    to: '/scan',
  };

  const buttons = [scanCardButton];

  return (
    <Stack spacing={4}>
      <Heading as="h3" size="lg">
        {t('actions')}
      </Heading>

      <ButtonList items={buttons} color="white" borderColor="white" />
      <FAB
        colorScheme="brand"
        aria-label="Scan card"
        icon={<RiQrCodeLine size={30} />}
        onClick={handleScanClick}
      />
    </Stack>
  );
});

Actions.displayName = 'Actions';
