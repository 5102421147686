import { memo } from 'react';
import { Image, ImageProps } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import cardAsset from '../assets/images/wavecard.svg';

type Props = ImageProps;

export const AnimatedCard = memo<Props>((props) => {
  const { src, ...rest } = props;

  return (
    <Image
      as={motion.img}
      rounded="2xl"
      shadow="2xl"
      backgroundColor="transparent"
      src={src ?? cardAsset}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      {...rest}
    />
  );
});

AnimatedCard.displayName = 'AnimatedCard';
