import { gql } from '@apollo/client';
import { USER_DATA_FRAGMENT_SHALLOW } from '../fragments/userData';

export const DELETE_BUSINESS_USER_MUTATION = gql`
  mutation deleteBusinessUser($input: DeleteBusinessUserMutationInput!) {
    deleteBusinessUser(input: $input) {
      user {
        ...UserDataShallow
      }
    }
  }

  ${USER_DATA_FRAGMENT_SHALLOW}
`;
