import { gql } from '@apollo/client';
import { REGISTRATION_DATA_FRAGMENT } from '../fragments/registrationData';

export const GET_REGISTRATION_QUERY = gql`
  query getRegistration($_id: String!) {
    registration: getRegistration(_id: $_id) {
      ...RegistrationData
    }
  }

  ${REGISTRATION_DATA_FRAGMENT}
`;
