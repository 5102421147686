import { gql } from '@apollo/client';
import { USER_DATA_FRAGMENT_DEEP } from '../fragments/userData';

export const GET_USER_QUERY = gql`
  query getUser($_id: String!, $devicesSkip: Int, $devicesLimit: Int) {
    user: getUser(_id: $_id) {
      ...UserDataDeep
    }
  }

  ${USER_DATA_FRAGMENT_DEEP}
`;
