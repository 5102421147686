import { Avatar, AvatarBadge, AvatarProps } from '@chakra-ui/react';
import { match } from '@trifence/utilities';
import { times } from '@companyon/constants';

type Props = AvatarProps & {
  name: string;
  color: string;
  lastSeenAt: number;
};

export function AvatarWithBadge(props: Props) {
  const { name, color, lastSeenAt, ...rest } = props;

  const now = Date.now();

  const badgeBackgroundColor = match<number, string>(lastSeenAt)
    .when(
      (lastSeenAt) => now - lastSeenAt < times.DEVICE_ACTIVE,
      () => 'green.500',
    )
    .when(
      (lastSeenAt) => now - lastSeenAt < times.DEVICE_RECENTLY_ACTIVE,
      () => 'yellow.500',
    )
    .otherwise(() => 'gray.500');

  return (
    <Avatar
      size="sm"
      name={name}
      backgroundColor={color}
      textColor="white"
      {...rest}
    >
      <AvatarBadge boxSize="1em" backgroundColor={badgeBackgroundColor} />
    </Avatar>
  );
}
