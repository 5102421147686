import { gql } from '@apollo/client';
import { TRANSACTION_DATA_FRAGMENT } from './transactionData';
import { CARD_ATTACHMENT_DATA_FRAGMENT } from './cardAttachmentData';

export const CARD_DATA_FRAGMENT = gql`
  fragment CardData on Card {
    _id
    createdAt
    updatedAt
    deletedAt
    balance
    transactions(skip: $transactionsSkip, limit: $transactionsLimit) {
      ...TransactionData
    }
    transactionsCount
    isLinked
    isLinkedToViewer
    designUrl
    attachment {
      ...CardAttachmentData
    }
  }

  ${TRANSACTION_DATA_FRAGMENT}
  ${CARD_ATTACHMENT_DATA_FRAGMENT}
`;
