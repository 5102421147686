import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useStorage } from '@companyon/hooks';

type Props = RouteProps;

export function ProtectedRoute(props: Props) {
  const { children, ...rest } = props;

  const [{ deviceId }] = useStorage();

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (deviceId) {
          return children;
        }

        return (
          <Redirect
            to={{
              pathname: '/',
              state: {
                from: location,
              },
            }}
          />
        );
      }}
    />
  );
}
