import { useRef, useCallback, useEffect } from 'react';
import { Box, IconButton, IconButtonProps } from '@chakra-ui/react';
import { motion, useAnimation } from 'framer-motion';
import { useDocumentVisibility } from '../hooks/useDocumentVisibility';
import { useInterval } from '../hooks/useInterval';

type Props = IconButtonProps & {
  id?: string;
  initialProgress: number;
  duration: number;
  onAnimationComplete?: () => void;
};

export function CountdownIconButton(props: Props) {
  const { id, initialProgress, duration, onAnimationComplete, ...rest } = props;

  const width = 150;
  const strokeWidth = 16;

  const radius = width / 2 - strokeWidth * 2;
  const circumference = 2 * Math.PI * radius;

  const initialProgressRef = useRef(initialProgress);
  const controls = useAnimation();
  const { isHidden } = useDocumentVisibility();

  const animate = useCallback(
    (progress: number) => {
      const clampedProgress = Math.min(progress, 1);

      controls.stop();

      controls.set({
        strokeDashoffset: circumference * clampedProgress,
      });

      controls.start({
        strokeDashoffset: circumference,
        transition: {
          ease: 'linear',
          duration: duration * (1 - clampedProgress),
        },
      });
    },
    [duration, controls, circumference],
  );

  useEffect(() => {
    animate(0);
  }, [id, animate]);

  useEffect(() => {
    animate(initialProgressRef.current);
    return controls.stop;
  }, [duration, controls, animate]);

  /**
   * Drive animation manually every second using setInterval when
   * the document is hidden, because requestAnimationFrame()
   * is paused by the browser.
   */
  useInterval((secondsElapsed) => {
    if (isHidden) {
      const progress = initialProgressRef.current + secondsElapsed / duration;
      animate(progress);
    }

    return controls.stop;
  }, 1000);

  return (
    <Box position="relative" width="48px" height="48px">
      <IconButton
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
        }}
        {...rest}
      />

      <svg
        style={{
          position: 'absolute',
          transform: 'rotate(-90deg)',
          overflow: 'visible',
          top: 0,
          left: 0,
          pointerEvents: 'none',
        }}
        viewBox="0 0 100 100"
        width="100%"
        height="100%"
      >
        <motion.circle
          cx="50%"
          cy="50%"
          r={radius}
          strokeWidth={strokeWidth}
          stroke="#718096"
          fill="transparent"
          strokeDasharray={circumference}
          strokeDashoffset={0}
          animate={controls}
          onAnimationComplete={onAnimationComplete}
        />
      </svg>
    </Box>
  );
}
