import { useState, useRef, useEffect, FormEvent, KeyboardEvent } from 'react';
import {
  Box,
  Stack,
  VStack,
  Text,
  InputGroup,
  InputLeftAddon,
  Image,
  Avatar,
  Button,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  NumberInput,
  NumberInputField,
  useToast,
} from '@chakra-ui/react';
import { IoMdArrowForward } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import {
  formatCurrency,
  multiply,
  divide,
  amountToString,
} from '@trifence/utilities';
import { useCreditCardMutation } from '../graphql/hooks/mutations/creditCard';
import { useDebitCardMutation } from '../graphql/hooks/mutations/debitCard';
import cardAsset from '../assets/images/wavecard.svg';
import { RiQuestionLine } from 'react-icons/ri';

type Device = any;
type Business = any;
type Card = any;

type Props = {
  device: Partial<Device>;
  business: Partial<Business>;
  card: Partial<Card>;
  intendedOperation: 'DEBIT' | 'CREDIT';
  onClose?: () => void;
};

const relaxedNumericPattern = '^[0-9]{0,3}([.,][0-9]{0,2})?$';
const relaxedNumericRE = new RegExp(relaxedNumericPattern);

function relaxedNumericAmount(s: string): number {
  if (relaxedNumericRE.test(s)) {
    return Number(s.replace(',', '.'));
  } else {
    return 0;
  }
}

export function TransactionCreationModal(props: Props) {
  const { device, business, card, intendedOperation, onClose } = props;

  const { t } = useTranslation('card');
  const addToast = useToast();

  const [creditCard, creditCardMutation] = useCreditCardMutation({
    onCompleted: (data: any) => {
      if (data?.creditCard) {
        const amount = data.creditCard.card?.transactions?.[0]?.amount;
        const amountStr = amount ? amountToString(amount) : '??';
        addToast({
          status: 'success',
          title: t('transactionCreationModal.toast.cardCredited', {
            amount: amountStr,
          }),
        });
      }
    },
  });

  const [debitCard, debitCardMutation] = useDebitCardMutation({
    onCompleted: (data: any) => {
      if (data?.debitCard) {
        const amount = data.debitCard.card?.transactions?.[0]?.amount;
        const amountStr = amount ? amountToString(-amount) : '??';
        addToast({
          status: 'success',
          title: t('transactionCreationModal.toast.cardDebited', {
            amount: amountStr,
          }),
        });
      }
    },
  });

  const [amount, setAmount] = useState('');
  const [isCreditOperation, setCreditOperation] = useState(
    intendedOperation === 'CREDIT',
  );
  const amountInputRef = useRef<HTMLInputElement>(null);

  const operation = isCreditOperation ? 'CREDIT' : 'DEBIT';

  const { titleText, submitButtonText } = {
    titleText: t(`transactionCreationModal.title.${operation}`),
    submitButtonText: t(`transactionCreationModal.submitButton.${operation}`),
  };

  const {
    cardBalance,
    businessBalance,
    businessOverdraftLimit,
    canViewBusinessBalance,
  } = {
    cardBalance: card.balance ?? 0,
    businessBalance: business.balance ?? 0,
    businessOverdraftLimit: business.overdraftLimit?.amount ?? 0,
    canViewBusinessBalance: device.permissions.viewBusinessBalance,
  };

  const availableBalance = isCreditOperation
    ? businessBalance + businessOverdraftLimit
    : cardBalance;

  const comparator = canViewBusinessBalance ? Math.min : Math.max;

  const { INPUT_MIN_AMOUNT, MIN_AMOUNT, MAX_AMOUNT } = {
    INPUT_MIN_AMOUNT: 0.0,
    MIN_AMOUNT: 0.01,
    MAX_AMOUNT: comparator(divide(availableBalance, 100), 250),
  };

  useEffect(() => {
    setAmount('');
    amountInputRef.current?.focus();
  }, [operation]);

  function isValidAmount() {
    const numericAmount = relaxedNumericAmount(amount);
    return MIN_AMOUNT <= numericAmount && numericAmount <= MAX_AMOUNT;
  }

  function handleReverseDirectionClick() {
    setCreditOperation((isCreditOperation) => !isCreditOperation);
  }

  function handleClose() {
    onClose?.();
  }

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();

    const numericAmount = multiply(relaxedNumericAmount(amount), 100);
    const mutation = isCreditOperation ? creditCard : debitCard;

    await mutation({
      variables: {
        input: {
          cardId: card._id,
          amount: numericAmount,
        },
      },
    });
    handleClose();
  }

  function convertCommaToDecimalDot(e: KeyboardEvent) {
    if (e.metaKey || e.ctrlKey || e.altKey) {
      return;
    }
    if (e.key === ',') {
      const inputField = amountInputRef.current;
      if (inputField) {
        var s = inputField.value;
        var i = inputField.selectionStart ?? s.length;
        s =
          s.substr(0, i) + '.' + s.substr(inputField.selectionEnd ?? s.length);
        inputField.value = s;
        inputField.selectionStart = inputField.selectionEnd = i + 1;
      }
      e.preventDefault();
      e.stopPropagation();
    }
  }

  return (
    <Modal isCentered={false} isOpen={true} onClose={handleClose}>
      <ModalOverlay />

      <form onSubmit={handleSubmit}>
        <ModalContent>
          <ModalHeader>{titleText}</ModalHeader>

          <ModalCloseButton />

          <ModalBody paddingX={12} paddingY={8}>
            <VStack spacing={8}>
              <Stack
                direction={isCreditOperation ? 'row' : 'row-reverse'}
                align="center"
                spacing={6}
              >
                <Avatar
                  boxSize={16}
                  rounded="full"
                  objectFit="cover"
                  src={business.logoUrl}
                  icon={<RiQuestionLine size={40} />}
                  alt="Business logo"
                >
                  <Box
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    insetEnd="0"
                    top="0"
                    boxSize="1em"
                    backgroundColor={device.color}
                    rounded="full"
                  />
                </Avatar>

                <IconButton
                  rounded="full"
                  icon={<IoMdArrowForward size={24} />}
                  aria-label="Reverse direction"
                  onClick={handleReverseDirectionClick}
                />

                <Image
                  height={16}
                  rounded="lg"
                  src={card.designUrl}
                  fallbackSrc={cardAsset}
                  alt="Card design"
                />
              </Stack>

              <VStack width="75%">
                <InputGroup size="lg">
                  <InputLeftAddon pointerEvents="none">CHF</InputLeftAddon>

                  <NumberInput
                    min={INPUT_MIN_AMOUNT}
                    max={MAX_AMOUNT}
                    precision={2}
                    step={0.01}
                    value={amount}
                    onChange={setAmount}
                  >
                    <NumberInputField
                      ref={amountInputRef}
                      autoFocus={true}
                      borderTopLeftRadius={0}
                      borderBottomLeftRadius={0}
                      pattern={relaxedNumericPattern}
                      onKeyDown={convertCommaToDecimalDot}
                    />
                  </NumberInput>
                </InputGroup>

                {canViewBusinessBalance || !isCreditOperation ? (
                  <Text fontSize="sm" textAlign="center">
                    {[
                      formatCurrency()(divide(availableBalance, 100)),
                      t('transactionCreationModal.available'),
                    ].join(' ')}
                  </Text>
                ) : null}
              </VStack>

              <Button
                type="submit"
                width="75%"
                size="lg"
                colorScheme="brand"
                isLoading={
                  debitCardMutation.loading || creditCardMutation.loading
                }
                isDisabled={!isValidAmount()}
              >
                {submitButtonText}
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </form>
    </Modal>
  );
}
