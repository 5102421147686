import { gql } from '@apollo/client';
import { TRANSACTION_DATA_FRAGMENT } from './transactionData';
import { USER_DATA_FRAGMENT_SHALLOW } from './userData';

export const BUSINESS_DATA_FRAGMENT = gql`
  fragment BusinessData on Business {
    _id
    name
    logoUrl
    createdAt
    updatedAt
    deletedAt
    balance
    transactions(skip: $transactionsSkip, limit: $transactionsLimit) {
      ...TransactionData
    }
    transactionsCount
    users(skip: $usersSkip, limit: $usersLimit) {
      ...UserDataShallow
    }
    usersCount
    devicesCount
    overdraftLimit {
      amount
    }
  }

  ${TRANSACTION_DATA_FRAGMENT}
  ${USER_DATA_FRAGMENT_SHALLOW}
`;
