import { gql } from '@apollo/client';

export const USER_DATA_FRAGMENT_SHALLOW = gql`
  fragment UserDataShallow on User {
    _id
    role
    email
    name {
      first
      last
    }
    employerId
    employer {
      _id
      name
      logoUrl
    }
    createdAt
    updatedAt
    deletedAt
    devicesCount
  }
`;

export const USER_DATA_FRAGMENT_DEEP = gql`
  fragment UserDataDeep on User {
    _id
    role
    email
    name {
      first
      last
    }
    createdAt
    updatedAt
    deletedAt
    devices(skip: $devicesSkip, limit: $devicesLimit) {
      _id
      name
      role
      ownerId
      activatedAt
      createdAt
      lastSeenAt
      color
      registration {
        _id
        url
      }
      owner {
        _id
        name {
          first
          last
        }
      }
    }
    devicesCount
  }
`;
