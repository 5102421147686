import { useSubscription } from '@apollo/client';
import { useQuery, QueryHookOptions } from '@companyon/graphql';
import { GET_USER_QUERY } from '../../schema/queries/getUser';
import { USER_DATA_CHANGED_SUBSCRIPTION } from '../../schema/subscriptions/userDataChanged';

export function useGetUserQuery(options?: QueryHookOptions) {
  const query = useQuery(GET_USER_QUERY, options);

  useSubscription(USER_DATA_CHANGED_SUBSCRIPTION, {
    variables: options?.variables,
    skip: options?.skip,
    onSubscriptionData: ({ client, subscriptionData }) => {
      const previousQueryResult = client.readQuery({
        query: GET_USER_QUERY,
        variables: options?.variables,
      });
      const user = {
        ...previousQueryResult?.user,
        ...subscriptionData.data.userDataChanged,
      };

      client.writeQuery({
        query: GET_USER_QUERY,
        variables: options?.variables,
        data: { user },
      });
    },
  });

  return query;
}
