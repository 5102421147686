import { gql } from '@apollo/client';

export const TRANSACTION_DATA_FRAGMENT = gql`
  fragment TransactionData on Transaction {
    _id
    businessId
    cardId
    amount
    cancellationExpiresAt
    isCancelled
    createdAt
    business {
      _id
      name
      logoUrl
    }
    card {
      _id
      serial
      designUrl
    }
    device {
      color
    }
  }
`;
