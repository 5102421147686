import { gql } from '@apollo/client';

export const SIGNUP_BUSINESS_USER_MUTATION = gql`
  mutation signupBusinessUser($input: SignupBusinessUserMutationInput!) {
    signupBusinessUser(input: $input) {
      registration {
        _id
      }
    }
  }
`;
