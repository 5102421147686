import { useState, useMemo } from 'react';
import {
  Box,
  Stack,
  HStack,
  Text,
  Button,
  Input,
  Select,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Alert,
  AlertIcon,
  useToast,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { fromNow } from '@trifence/utilities';
import { useGetDeviceQuery } from '../graphql/hooks/queries/getDevice';
import { useRegisterBusinessDeviceMutation } from '../graphql/hooks/mutations/registerBusinessDevice';
import { useUpdateBusinessDeviceMutation } from '../graphql/hooks/mutations/updateBusinessDevice';
import { useStorage } from '@companyon/hooks';

type User = any;
type Device = any;
type BusinessDeviceAuthorizationRole = any;

type FormData = {
  role: BusinessDeviceAuthorizationRole;
  name: string;
};

type Mode = 'VIEW' | 'CREATE' | 'EDIT';

type Props = {
  mode?: Mode;
  device: Device | null;
  owner: User;
  onClose?: () => void;
  onDeviceCreation?: (registrationUrl: string) => void;
};

export function DeviceModal(props: Props) {
  const {
    mode: initialMode = 'VIEW',
    device,
    owner,
    onClose,
    onDeviceCreation,
  } = props;

  const [mode, setMode] = useState<Mode>(initialMode);

  const [{ deviceId }] = useStorage();
  const {
    t,
    i18n: { language: locale },
  } = useTranslation(['deviceModal', 'forms', 'authorization']);
  const addToast = useToast();

  const getDeviceQuery = useGetDeviceQuery({
    variables: {
      _id: deviceId,
    },
  });

  const [registerBusinessDevice, registerBusinessDeviceMutation] =
    useRegisterBusinessDeviceMutation({
      onCompleted: (data: any) => {
        if (data?.registerBusinessDevice) {
          addToast({
            status: 'success',
            title: t(`toast.success.${mode}`),
          });
        }
      },
    });

  const [updateBusinessDevice, updateBusinessDeviceMutation] =
    useUpdateBusinessDeviceMutation({
      onCompleted: (data: any) => {
        if (data?.updateBusinessDevice) {
          addToast({
            status: 'success',
            title: t(`toast.success.${mode}`),
          });
        }
      },
    });

  const form = useForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      role: device?.role,
      name: device?.name,
    },
  });

  const roles = useMemo(() => {
    if (owner.role === 'BUSINESS_OWNER') {
      return [
        'BUSINESS_OWNER',
        'BUSINESS_MANAGER',
        'BUSINESS_TRUSTED_POS',
        'BUSINESS_POS',
      ];
    }

    return ['BUSINESS_MANAGER', 'BUSINESS_TRUSTED_POS', 'BUSINESS_POS'];
  }, [owner]);

  const isViewMode = mode === 'VIEW';
  const isEditMode = mode === 'EDIT';
  const isCreateMode = mode === 'CREATE';
  const isViewingOwnDevice = getDeviceQuery.data?.device._id === device?._id;

  const requiredValidationRules = {
    required: {
      value: true,
      message: t('forms:required'),
    },
  };

  const nameValidationRules = {
    minLength: {
      value: 2,
      message: t('forms:tooShort'),
    },
    maxLength: {
      value: 100,
      message: t('forms:tooLong'),
    },
  };

  function isInvalidForm() {
    const areRequiredFieldsFilled =
      form.getValues('role') && form.getValues('name');

    const doErrorsExist = form.errors.role || form.errors.name;

    return !areRequiredFieldsFilled || doErrorsExist;
  }

  async function handleFormSubmit(formData: FormData) {
    const { role, name } = formData;

    if (isCreateMode) {
      const response = await registerBusinessDevice({
        variables: {
          input: {
            ownerId: owner._id,
            role,
            name,
          },
        },
      });

      const { url } = response.data?.registerBusinessDevice.device.registration;

      onDeviceCreation?.(url);
    }

    if (isEditMode) {
      await updateBusinessDevice({
        variables: {
          input: {
            _id: device?._id,
            role: role ?? device?.role,
            name: name ?? device?.name,
          },
        },
      });
    }

    handleClose();
  }

  function handleClose() {
    onClose?.();
  }

  return (
    <Modal isCentered={false} isOpen={true} onClose={handleClose}>
      <ModalOverlay />

      <form onSubmit={form.handleSubmit(handleFormSubmit)}>
        <ModalContent>
          <ModalHeader>
            <HStack>
              <Text>{t(`title.${mode}`)}</Text>

              <Box boxSize={4} rounded="full" backgroundColor={device?.color} />
            </HStack>
          </ModalHeader>

          <ModalCloseButton />

          <ModalBody>
            <Stack spacing={4}>
              {(() => {
                if (isViewMode) {
                  return (
                    <Table variant="simple" colorScheme="brand">
                      <Thead>
                        <Tr>
                          <Th width="30%"></Th>
                          <Th></Th>
                        </Tr>
                      </Thead>

                      <Tbody>
                        <Tr>
                          <Td>{t('label.short.owner')}</Td>
                          <Td>
                            {[
                              device?.owner.name.first,
                              device?.owner.name.last,
                            ].join(' ')}
                          </Td>
                        </Tr>

                        <Tr>
                          <Td>{t('label.short.role')}</Td>
                          <Td>
                            {t(`authorization:roles.long.${device?.role}`)}
                          </Td>
                        </Tr>

                        <Tr>
                          <Td>{t('label.short.device')}</Td>
                          <Td>{device?.name}</Td>
                        </Tr>

                        <Tr>
                          <Td>{t('label.short.creation')}</Td>
                          <Td>
                            {fromNow({ locale, granular: true })(
                              device.createdAt,
                            )}
                          </Td>
                        </Tr>

                        <Tr>
                          <Td>{t('label.short.activation')}</Td>
                          <Td>
                            {device.activatedAt
                              ? fromNow({ locale, granular: true })(
                                  device.activatedAt,
                                )
                              : '-'}
                          </Td>
                        </Tr>

                        <Tr>
                          <Td>{t('label.short.lastSeen')}</Td>
                          <Td>
                            {device.lastSeenAt
                              ? fromNow({ locale, granular: true })(
                                  device.lastSeenAt - 1000,
                                )
                              : '-'}
                          </Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  );
                }

                return (
                  <Stack spacing={4}>
                    <FormControl>
                      <FormLabel htmlFor="ownerId">
                        {t('label.long.owner')}
                      </FormLabel>

                      <Select
                        variant="filled"
                        placeholder={t('placeholder.owner')}
                        defaultValue={owner._id}
                        isDisabled={true}
                      >
                        <option value={owner._id}>
                          {[owner.name.first, owner.name.last].join(' ')}
                        </option>
                      </Select>
                    </FormControl>

                    <FormControl>
                      <FormLabel htmlFor="role">
                        {t('label.long.role')}
                      </FormLabel>

                      <Select
                        ref={form.register(requiredValidationRules)}
                        name="role"
                        variant="filled"
                        placeholder={t('placeholder.role')}
                        isDisabled={isViewingOwnDevice}
                      >
                        {roles.map((role) => (
                          <option value={role} key={role}>
                            {t(`authorization:roles.long.${role}`)}
                          </option>
                        ))}
                      </Select>

                      <FormErrorMessage>
                        {form.errors.role?.message}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={Boolean(form.errors.name)}>
                      <FormLabel htmlFor="name">
                        {t('label.long.device')}
                      </FormLabel>

                      <Input
                        ref={form.register({
                          ...requiredValidationRules,
                          ...nameValidationRules,
                        })}
                        name="name"
                        type="text"
                        variant="filled"
                        placeholder={t('placeholder.device')}
                      />

                      <FormErrorMessage>
                        {form.errors.name?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Stack>
                );
              })()}

              {isViewingOwnDevice ? (
                <Alert status="info" variant="left-accent">
                  <AlertIcon />
                  {t('alert.currentDevice')}
                </Alert>
              ) : null}
            </Stack>
          </ModalBody>

          <ModalFooter>
            <HStack>
              <Button onClick={onClose}>{t('cancel')}</Button>

              <Button
                type={isViewMode ? 'button' : 'submit'}
                colorScheme="brand"
                isLoading={
                  registerBusinessDeviceMutation.loading ||
                  updateBusinessDeviceMutation.loading
                }
                isDisabled={!isViewMode && isInvalidForm()}
                key={isViewMode ? 'edit' : 'submit'}
                onClick={() => {
                  if (isViewMode) {
                    setMode('EDIT');
                  }
                }}
              >
                {t(`submit.${mode}`)}
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
}
