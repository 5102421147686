import { gql } from '@apollo/client';
import { CARD_DATA_FRAGMENT } from '../fragments/cardData';

export const CREDIT_CARD_MUTATION = gql`
  mutation creditCard(
    $input: CreditCardMutationInput!
    $transactionsSkip: Int
    $transactionsLimit: Int
  ) {
    creditCard(input: $input) {
      card {
        ...CardData
      }
    }
  }

  ${CARD_DATA_FRAGMENT}
`;
