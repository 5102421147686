import { gql } from '@apollo/client';
import { DEVICE_DATA_FRAGMENT_DEEP } from '../fragments/deviceData';

export const DEVICE_DATA_CHANGED_SUBSCRIPTION = gql`
  subscription deviceDataChanged($_id: String!) {
    deviceDataChanged(_id: $_id) {
      ...DeviceDataDeep
    }
  }

  ${DEVICE_DATA_FRAGMENT_DEEP}
`;
