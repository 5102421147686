import {
  Stack,
  HStack,
  Button,
  Input,
  Select,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
  Text,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isEmail } from '@trifence/utilities';
import { useSignupBusinessUserMutation } from '../graphql/hooks/mutations/signupBusinessUser';

type BusinessUserAuthorizationRole = any;

type FormData = {
  role: BusinessUserAuthorizationRole;
  firstName: string;
  lastName: string;
  email: string;
};

type Props = {
  isOpen: boolean;
  onClose?: () => void;
};

export function UserCreationModal(props: Props) {
  const { isOpen, onClose } = props;

  const { t } = useTranslation(['users', 'forms', 'authorization']);
  const addToast = useToast();

  const [signupBusinessUser, signupBusinessUserMutation] =
    useSignupBusinessUserMutation({
      onCompleted: (data: any) => {
        if (data?.signupBusinessUser) {
          addToast({
            status: 'success',
            title: t('userCreationModal.toast.userCreated'),
          });
        }
      },
    });

  const form = useForm<FormData>({ mode: 'onChange' });

  const roles = ['BUSINESS_OWNER', 'BUSINESS_MANAGER'];

  const requiredValidationRules = {
    required: {
      value: true,
      message: t('forms:required'),
    },
  };

  const nameValidationRules = {
    minLength: {
      value: 2,
      message: t('forms:tooShort'),
    },
    maxLength: {
      value: 100,
      message: t('forms:tooLong'),
    },
  };

  const emailValidationRules = {
    validate: (str: string) => {
      return isEmail(str) ? true : t('forms:invalidEmail');
    },
  };

  function isInvalidForm() {
    const areRequiredFieldsFilled =
      form.getValues('role') &&
      form.getValues('firstName') &&
      form.getValues('lastName') &&
      form.getValues('email');

    const doErrorsExist =
      form.errors.role ||
      form.errors.firstName ||
      form.errors.lastName ||
      form.errors.email;

    return !areRequiredFieldsFilled || doErrorsExist;
  }

  async function handleFormSubmit(formData: FormData) {
    const { role, firstName, lastName, email } = formData;

    await signupBusinessUser({
      variables: {
        input: {
          role,
          name: {
            first: firstName,
            last: lastName,
          },
          email,
        },
      },
    });

    handleClose();
  }

  function handleClose() {
    onClose?.();
  }

  return (
    <Modal isCentered={false} isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />

      <form onSubmit={form.handleSubmit(handleFormSubmit)}>
        <ModalContent>
          <ModalHeader>{t('userCreationModal.title')}</ModalHeader>

          <ModalCloseButton />

          <ModalBody>
            <Stack spacing={4}>
              <FormControl>
                <FormLabel htmlFor="role">
                  {t('userCreationModal.label.role')}
                </FormLabel>

                <Select
                  ref={form.register(requiredValidationRules)}
                  name="role"
                  variant="filled"
                  placeholder={t('userCreationModal.placeholder.role')}
                >
                  {roles.map((role) => (
                    <option value={role} key={role}>
                      {t(`authorization:roles.long.${role}`)}
                    </option>
                  ))}
                </Select>

                <FormErrorMessage>{form.errors.role?.message}</FormErrorMessage>
              </FormControl>

              <Text paddingTop="4" paddingBottom="0" fontWeight="bold">
                {t('userCreationModal.subtitle')}
              </Text>

              <FormControl isInvalid={Boolean(form.errors.firstName)}>
                <FormLabel htmlFor="firstName">
                  {t('userCreationModal.label.firstName')}
                </FormLabel>

                <Input
                  ref={form.register({
                    ...requiredValidationRules,
                    ...nameValidationRules,
                  })}
                  name="firstName"
                  type="text"
                  variant="filled"
                  placeholder={t('userCreationModal.placeholder.firstName')}
                />

                <FormErrorMessage>
                  {form.errors.firstName?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={Boolean(form.errors.lastName)}>
                <FormLabel htmlFor="lastName">
                  {t('userCreationModal.label.lastName')}
                </FormLabel>

                <Input
                  ref={form.register({
                    ...requiredValidationRules,
                    ...nameValidationRules,
                  })}
                  name="lastName"
                  type="text"
                  variant="filled"
                  placeholder={t('userCreationModal.placeholder.lastName')}
                />

                <FormErrorMessage>
                  {form.errors.lastName?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={Boolean(form.errors.email)}>
                <FormLabel htmlFor="email">
                  {t('userCreationModal.label.email')}
                </FormLabel>

                <Input
                  ref={form.register({
                    ...requiredValidationRules,
                    ...emailValidationRules,
                  })}
                  name="email"
                  type="email"
                  variant="filled"
                  placeholder={t('userCreationModal.placeholder.email')}
                />

                <FormErrorMessage>
                  {form.errors.email?.message}
                </FormErrorMessage>
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <HStack>
              <Button onClick={onClose}>{t('userCreationModal.cancel')}</Button>

              <Button
                type="submit"
                colorScheme="brand"
                isLoading={signupBusinessUserMutation.loading}
                isDisabled={isInvalidForm()}
              >
                {t('userCreationModal.submit')}
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
}
