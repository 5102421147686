import { gql } from '@apollo/client';
import { USER_DATA_FRAGMENT_SHALLOW } from './userData';

export const DEVICE_DATA_FRAGMENT_SHALLOW = gql`
  fragment DeviceDataShallow on Device {
    _id
    name
    role
    ownerId
    activatedAt
    createdAt
    color
  }
`;

export const DEVICE_DATA_FRAGMENT_DEEP = gql`
  fragment DeviceDataDeep on Device {
    _id
    name
    role
    ownerId
    activatedAt
    createdAt
    lastSeenAt
    color
    owner {
      ...UserDataShallow
    }
    registration {
      _id
      url
    }
    permissions {
      viewBusinessBalance
      viewBusinessTransactions
      viewBusinessTransactionCount
      viewBusinessUsers
      viewBusinessUserCount
      viewBusinessDevices
      viewBusinessDeviceCount
      createBusinessUser
      createBusinessDevice
      deleteBusinessDevice
      viewCardTransactions
      viewCardTransactionCount
      cancelCardTransaction
      creditCard
      debitCard
    }
  }

  ${USER_DATA_FRAGMENT_SHALLOW}
`;
