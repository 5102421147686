import { gql } from '@apollo/client';

export const BUSINESS_TRANSACTIONS_SUMMARY_DATA_FRAGMENT = gql`
  fragment BusinessTransactionsSummaryData on BusinessTransactionsSummary {
    summary {
      credit
      debit
    }
    usersTransactionsSummaries {
      user {
        name {
          first
          last
        }
        deletedAt
      }
      summary {
        credit
        debit
      }
      devicesTransactionsSummaries {
        device {
          name
          color
          deletedAt
        }
        summary {
          credit
          debit
        }
      }
    }
  }
`;
