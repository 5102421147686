import { Switch, Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { manifestGeneratorUrl, selectAppEnv } from '@trifence/utilities';
import { useStorage, useLocation } from '@companyon/hooks';
import { ScrollToTop, Configuration } from '@companyon/components';
import { Header } from './pages/Header';
import { Landing } from './pages/Landing';
import { Scanner } from './pages/Scanner';
import { Dashboard } from './pages/Dashboard';
import { User } from './pages/User';
import { Card } from './pages/Card';
import { Staging } from './pages/Staging';
import { Registration } from './pages/Registration';
import { Transactions } from './pages/Transactions';
import { Users } from './pages/Users';
import { ErrorBoundary } from './pages/ErrorBoundary';
import { ProtectedRoute } from './pages/ProtectedRoute';
import { Help } from './pages/Help';
import { Recover } from './pages/Recover';
import { title as titleFunc, setAppName } from './utilities/appName';
import companyonAsset from './assets/images/companyon.svg';

export function Page() {
  const location = useLocation();
  const [{ deviceId, deviceIds, bearerTokens, language }] = useStorage();
  const { t } = useTranslation('landing');

  setAppName(t('appName'));
  const title = titleFunc();
  const description = t('subheading');
  const faviconUrl = selectAppEnv(['favicon.png', 'favicon-staging.png']);
  const appleTouchIconUrl = selectAppEnv([
    'logo192.png',
    'logo192-staging.png',
  ]);
  const manifestUrl = manifestGeneratorUrl(
    'merchant',
    deviceIds,
    bearerTokens,
    language,
  );

  // NOTE: <Header>, through <DeviceSwitcherButton>, also manages deviceIds

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="icon" href={faviconUrl} />
        <link rel="apple-touch-icon" href={appleTouchIconUrl} />
        <meta name="msapplication-TileImage" content={appleTouchIconUrl} />
        <meta property="og:locale" content={language} />
        <meta property="og:site_name" content={`${title}: ${description}`} />
        <meta property="og:url" content={window.location.pathname} />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={appleTouchIconUrl} />
        <meta property="og:image:url" content={companyonAsset} />
        <meta property="og:image:secure_url" content={companyonAsset} />
        <meta itemProp="name" content={title} />
        <meta itemProp="headline" content={`${title}: ${description}`} />
        <meta itemProp="image" content={companyonAsset} />
        <meta name="twitter:title" content={`${title}: ${description}`} />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="manifest" href={manifestUrl} />
        <link rel="canonical" href={window.location.pathname} />
        <link
          rel="alternate"
          hrefLang="de"
          href={`${window.location.pathname}?language=de`}
        />
        <link
          rel="alternate"
          hrefLang="en"
          href={`${window.location.pathname}?language=en`}
        />
      </Helmet>

      <Header />
      <ScrollToTop />
      <Switch>
        <Route path="/" exact={true}>
          <ErrorBoundary key={location.pathname}>
            {deviceId ? <Dashboard /> : <Landing />}
          </ErrorBoundary>
        </Route>

        <Route path="/config">
          <ErrorBoundary key={location.pathname}>
            <Configuration />
          </ErrorBoundary>{' '}
        </Route>

        <Route path="/scan">
          <ErrorBoundary key={location.pathname}>
            <Scanner hideHeading />
          </ErrorBoundary>{' '}
        </Route>

        <Route path="/help">
          <ErrorBoundary key={location.pathname}>
            <Help />
          </ErrorBoundary>
        </Route>

        <Route path="/staging">
          <ErrorBoundary key={location.pathname}>
            <Staging />
          </ErrorBoundary>
        </Route>

        <Route path="/register">
          <ErrorBoundary key={location.pathname}>
            <Registration />
          </ErrorBoundary>
        </Route>

        <Route path="/recover">
          <ErrorBoundary key={location.pathname}>
            <Recover />
          </ErrorBoundary>
        </Route>

        <ProtectedRoute path="/users/:userId">
          <ErrorBoundary key={location.pathname}>
            <User />
          </ErrorBoundary>
        </ProtectedRoute>

        <ProtectedRoute path="/card/:cardId">
          <ErrorBoundary key={location.pathname}>
            <Card />
          </ErrorBoundary>
        </ProtectedRoute>

        <ProtectedRoute path="/transactions">
          <ErrorBoundary key={location.pathname}>
            <Transactions />
          </ErrorBoundary>
        </ProtectedRoute>

        <ProtectedRoute path="/users">
          <ErrorBoundary key={location.pathname}>
            <Users />
          </ErrorBoundary>
        </ProtectedRoute>

        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </>
  );
}
