import { useState } from 'react';
import {
  Stack,
  HStack,
  Heading,
  IconButton,
  Tab,
  Tabs,
  TabPanel,
  TabPanels,
  TabList,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
} from '@chakra-ui/react';
import {
  Alerter,
  CenteredSpinner,
  ErrorIndicator,
  FAB,
} from '@companyon/components';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { RiQrCodeLine } from 'react-icons/ri';
import { IoMdTrash } from 'react-icons/io';
import { MdEdit, MdInfoOutline } from 'react-icons/md';
import { IoAddSharp } from 'react-icons/io5';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { pagination } from '@companyon/constants';
import { BackButton } from '../components/BackButton';
import {
  fromNow,
  formatTimestamp,
  generateQRCode,
  nowMillis,
} from '@trifence/utilities';
import { DeviceModal } from '../components/DeviceModal';
import { AvatarWithBadge } from '../components/AvatarWithBadge';
import { InfiniteScrollList } from '@companyon/components';
import { QRCodeModal } from '../components/QRCodeModal';
import { useGetDeviceQuery } from '../graphql/hooks/queries/getDevice';
import { useGetUserQuery } from '../graphql/hooks/queries/getUser';
import { useDeleteDeviceMutation } from '../graphql/hooks/mutations/deleteDevice';
import { useStorage } from '@companyon/hooks';

type Device = any;

type RouteParams = {
  userId: string;
};

let lastReturnHome: number | undefined;

export function User() {
  const history = useHistory();
  const { userId } = useParams<RouteParams>();
  const [{ deviceId }] = useStorage();
  const {
    t,
    i18n: { language: locale },
  } = useTranslation(['user', 'authorization']);
  const addToast = useToast();

  const getDeviceQuery = useGetDeviceQuery({
    variables: {
      _id: deviceId,
    },
  });

  const getUserQuery = useGetUserQuery({
    variables: {
      _id: userId,
      devicesSkip: 0,
      devicesLimit: pagination.PAGE_SIZE,
    },
  });

  const [deleteDevice, deleteDeviceMutation] = useDeleteDeviceMutation({
    onCompleted: (data: any) => {
      if (data?.deleteDevice) {
        addToast({
          status: 'success',
          title: t('toast.deviceDeleted'),
        });
      }
    },
  });

  const [deviceSelection, selectDevice] = useState<{
    _id: string | null;
    mode: 'VIEW' | 'CREATE' | 'EDIT';
  } | null>(null);
  const [selectedQRCode, setSelectedQRCode] = useState<string[]>([]);
  const [selectedDeviceForDeletion, setSelectedDeviceForDeletion] =
    useState<Device>(null);

  function closeDeviceModal() {
    selectDevice(null);
  }

  async function showQRCodeModal(registrationUrl: string) {
    const qrcodeDataUrl = await generateQRCode(registrationUrl);
    setSelectedQRCode([registrationUrl, qrcodeDataUrl]);
  }

  function closeQRCodeModal() {
    setSelectedQRCode([]);
  }

  function closeDeviceDeletionDialog() {
    setSelectedDeviceForDeletion(null);
  }

  async function handleDeviceDeletionConfirmation() {
    await deleteDevice({
      variables: {
        input: {
          deviceId: selectedDeviceForDeletion?._id,
        },
      },
    });

    closeDeviceDeletionDialog();
  }

  function handleDeviceCreation(registrationUrl: string) {
    showQRCodeModal(registrationUrl);
  }

  function handleLoadMoreDevices() {
    getUserQuery.fetchMore({
      variables: {
        devicesSkip: devices.length,
        devicesLimit: pagination.PAGE_SIZE,
      },
    });
  }

  if (getDeviceQuery.loading || getUserQuery.loading) {
    return <CenteredSpinner />;
  }

  if (!getDeviceQuery.data || !getUserQuery.data) {
    // Often, two toasts are generated. Workaround.
    if (!lastReturnHome || lastReturnHome + 1000 < nowMillis()) {
      lastReturnHome = nowMillis();
      addToast({ status: 'info', title: t('toast.backToRoot') });
    }
    history.push('/');
    // Continue showing the same screen until the redirect happened
    return <CenteredSpinner />;
  }

  const { device: viewingDevice } = getDeviceQuery.data;
  const { user } = getUserQuery.data;

  const {
    name,
    email,
    role,
    creation,
    devices,
    devicesCount,
    canViewBusinessUsers,
    canCreateBusinessDevices,
    canViewBusinessDevices,
    canDeleteBusinessDevices,
  } = {
    name: [user.name.first, user.name.last].join(' '),
    email: user.email,
    role: t(`authorization:roles.long.${user.role}`),
    creation: [
      formatTimestamp({ format: 'LLL', locale })(user.createdAt),
      `(${fromNow({ locale, granular: true })(user.createdAt)})`,
    ].join(' '),
    devices: user.devices ?? [],
    devicesCount: user.devicesCount ?? 0,
    canViewBusinessUsers: viewingDevice.permissions.viewBusinessUsers,
    canCreateBusinessDevices: viewingDevice.permissions.createBusinessDevice,
    canViewBusinessDevices: viewingDevice.permissions.viewBusinessDevices,
    canDeleteBusinessDevices: viewingDevice.permissions.deleteBusinessDevice,
  };

  const canLoadMoreDevices = devices.length < devicesCount;

  const selectedDevice = devices.find(
    (device: Device) => deviceSelection?._id === device._id,
  );

  if (!canViewBusinessUsers || !canViewBusinessDevices) {
    return (
      <ErrorIndicator
        heading={t('authorization:error.heading')}
        subheading={t('authorization:error.subheading')}
      />
    );
  }

  return (
    <Stack marginBottom={16} padding={{ base: 4, md: 8 }} spacing={8}>
      <HStack>
        <BackButton />

        <Heading as="h1" size="2xl">
          {name}
        </Heading>
      </HStack>

      <Tabs
        variant="soft-rounded"
        width="full"
        isFitted={true}
        colorScheme="brand"
        defaultIndex={history.location.hash === '#details' ? 0 : 1}
      >
        <TabList>
          <Tab>{t('info')}</Tab>
          <Tab>{t('devices')}</Tab>
        </TabList>

        <TabPanels>
          <TabPanel overflowX="scroll">
            <Table variant="striped">
              <Thead>
                <Tr>
                  <Th></Th>
                  <Th></Th>
                </Tr>
              </Thead>

              <Tbody>
                <Tr>
                  <Td>{t('email')}</Td>
                  <Td>{email}</Td>
                </Tr>

                <Tr>
                  <Td>{t('role')}</Td>
                  <Td>{role}</Td>
                </Tr>

                <Tr>
                  <Td>{t('creation')}</Td>
                  <Td>{creation}</Td>
                </Tr>

                <Tr>
                  <Td>{t('devices')}</Td>
                  <Td>{devicesCount}</Td>
                </Tr>
              </Tbody>
            </Table>
          </TabPanel>

          <TabPanel overflowX="scroll">
            <InfiniteScrollList
              spacing={2}
              canLoadMore={canLoadMoreDevices}
              onLoadMore={handleLoadMoreDevices}
            >
              <Table variant="simple" size="md">
                <Thead>
                  <Tr>
                    <Th></Th>
                    <Th>{t('name')}</Th>
                    <Th>{t('role')}</Th>
                    <Th></Th>
                  </Tr>
                </Thead>

                <Tbody>
                  {devices.map((device: any) => {
                    const {
                      _id,
                      color,
                      name,
                      role,
                      registrationUrl,
                      lastSeenAt,
                    } = {
                      _id: device._id,
                      color: device.color,
                      name: device.name ?? '-',
                      role: t(`authorization:roles.short.${device.role}`),
                      registrationUrl: device.registration.url,
                      lastSeenAt: device.lastSeenAt,
                    };

                    return (
                      <Tr
                        borderLeftWidth={viewingDevice._id === _id ? 2 : 0}
                        borderColor="brand.300"
                        cursor="pointer"
                        key={_id}
                      >
                        <Td
                          onClick={() => {
                            selectDevice({ _id, mode: 'VIEW' });
                          }}
                        >
                          <AvatarWithBadge
                            name={name}
                            color={color}
                            lastSeenAt={lastSeenAt}
                          />
                        </Td>

                        <Td
                          onClick={() => {
                            selectDevice({ _id, mode: 'VIEW' });
                          }}
                        >
                          {name}
                        </Td>

                        <Td
                          onClick={() => {
                            selectDevice({ _id, mode: 'VIEW' });
                          }}
                        >
                          {role}
                        </Td>

                        <Td>
                          <Menu>
                            <MenuButton
                              as={IconButton}
                              variant="ghost"
                              rounded="md"
                              icon={<BiDotsVerticalRounded />}
                              aria-label="Options"
                            />

                            <MenuList>
                              <MenuItem
                                icon={<MdInfoOutline size={16} />}
                                onClick={() => {
                                  selectDevice({ _id, mode: 'VIEW' });
                                }}
                              >
                                {t('details')}
                              </MenuItem>

                              <MenuItem
                                icon={<MdEdit size={16} />}
                                onClick={() => {
                                  selectDevice({ _id, mode: 'EDIT' });
                                }}
                              >
                                {t('edit')}
                              </MenuItem>

                              <MenuItem
                                icon={<RiQrCodeLine size={16} />}
                                onClick={() => {
                                  showQRCodeModal(registrationUrl);
                                }}
                              >
                                {t('qrCode')}
                              </MenuItem>

                              {canDeleteBusinessDevices ? (
                                <MenuItem
                                  color="red"
                                  icon={<IoMdTrash size={16} />}
                                  onClick={() => {
                                    setSelectedDeviceForDeletion(device);
                                  }}
                                >
                                  {t('delete')}
                                </MenuItem>
                              ) : null}
                            </MenuList>
                          </Menu>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </InfiniteScrollList>
          </TabPanel>
        </TabPanels>
      </Tabs>

      {deviceSelection ? (
        <DeviceModal
          mode={deviceSelection.mode}
          device={selectedDevice}
          owner={user}
          onClose={closeDeviceModal}
          onDeviceCreation={handleDeviceCreation}
        />
      ) : null}

      {selectedQRCode.length > 0 ? (
        <QRCodeModal
          registrationUrl={selectedQRCode[0]}
          dataUrl={selectedQRCode[1]}
          onClose={closeQRCodeModal}
        />
      ) : null}

      <Alerter
        headerText={t('deviceDeletionDialog.title')}
        cancelText={t('deviceDeletionDialog.cancel')}
        confirmationText={t('deviceDeletionDialog.confirm')}
        confirmationColorScheme="red"
        onConfirmation={handleDeviceDeletionConfirmation}
        disclosure={{
          isOpen: canDeleteBusinessDevices && selectedDeviceForDeletion,
          onClose: closeDeviceDeletionDialog,
        }}
        performingOperation={deleteDeviceMutation.loading}
      >
        {t('deviceDeletionDialog.confirmation')}
      </Alerter>

      {canCreateBusinessDevices ? (
        <FAB
          colorScheme="brand"
          aria-label="Create account"
          icon={<IoAddSharp size={30} />}
          onClick={() => {
            selectDevice({ _id: null, mode: 'CREATE' });
          }}
        />
      ) : null}
    </Stack>
  );
}
