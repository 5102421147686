import { gql } from '@apollo/client';
import { USER_DATA_FRAGMENT_DEEP } from '../fragments/userData';

export const USER_DATA_CHANGED_SUBSCRIPTION = gql`
  subscription userDataChanged(
    $_id: String!
    $devicesSkip: Int
    $devicesLimit: Int
  ) {
    userDataChanged(_id: $_id) {
      ...UserDataDeep
    }
  }

  ${USER_DATA_FRAGMENT_DEEP}
`;
