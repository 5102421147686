import { useState } from 'react';
import useEvent from 'react-use/lib/useEvent';

type State = {
  isHidden: boolean;
};

export function useDocumentVisibility(): State {
  const [isHidden, setHidden] = useState(false);

  useEvent('visibilitychange', handleVisibilityChange);

  function handleVisibilityChange() {
    setHidden(document.hidden);
  }

  return {
    isHidden,
  };
}
