import {
  Box,
  Center,
  Container,
  Stack,
  Heading,
  Button,
  Text,
  Alert,
  AlertIcon,
  useToast,
} from '@chakra-ui/react';
import { Redirect } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useQueryParams } from '@companyon/hooks';
import { useStorage } from '@companyon/hooks';
import { useGetDeviceQuery } from '../graphql/hooks/queries/getDevice';
import { urls } from '@companyon/constants';

// Copy credentials from production to staging (or development to development)
export function Staging() {
  const [{ deviceId, bearerToken }, { pickDeviceId, setBearerToken }] =
    useStorage();
  const queryParams = useQueryParams();
  const { t } = useTranslation(['staging']);
  const newDeviceId = queryParams.get('deviceId');
  const newBearerToken = queryParams.get('bearerToken');
  const getDeviceQuery = useGetDeviceQuery({
    skip: !Boolean(deviceId),
    variables: {
      _id: deviceId,
    },
  });
  const addToast = useToast();

  if (process.env.REACT_APP_ENV !== 'production' && newDeviceId) {
    // Credential receiving side
    return (
      <Center>
        <Container maxW="3xl" centerContent>
          <Stack marginBottom={16} padding={{ base: 4, md: 8 }} spacing={8}>
            <Heading
              as="h1"
              textColor="pink.500"
              textAlign={{ base: 'center', md: 'left' }}
              size="2xl"
            >
              {t('welcome')}
            </Heading>
            <Text>{t('explanation')}</Text>
            <Alert status="warning">
              <AlertIcon />
              {t('warning1')}
            </Alert>
            <Alert status="warning">
              <AlertIcon />
              {t('warning2')}
            </Alert>
            <Text>
              <Trans t={t} i18nKey="recognize">
                Staging windows have the word «<code>staging</code>» in the icon
                and the URL.
              </Trans>
            </Text>
            <Button
              colorScheme="pink"
              textColor="black"
              size="lg"
              onClick={(e) => {
                if (newBearerToken) {
                  setBearerToken(newDeviceId, newBearerToken);
                }
                pickDeviceId(newDeviceId);
                // Reload app, eliminating secrets from history
                window.location.replace('/');
              }}
            >
              {t('start')}
            </Button>
          </Stack>
        </Container>
      </Center>
    );
  } else if (process.env.REACT_APP_ENV !== 'staging' && deviceId) {
    // Credential sending side
    const { name, color } = {
      name: getDeviceQuery.data?.device.name,
      color: getDeviceQuery.data?.device.color,
    };
    const baseURL =
      process.env.REACT_APP_ENV === 'production'
        ? urls.web.merchant.staging
        : urls.web.merchant.development;
    const stagingActivationURL =
      `${baseURL}/staging?deviceId=${deviceId}` +
      (bearerToken ? `&bearerToken=${bearerToken}` : '');
    return (
      <Center>
        <Container maxW="3xl" centerContent>
          <Stack marginBottom={16} padding={{ base: 4, md: 8 }} spacing={8}>
            <Heading
              as="h1"
              textColor="yellow"
              textAlign={{ base: 'center', md: 'left' }}
              size="2xl"
            >
              {t('title')}
            </Heading>
            <Text>{t('explanation')}</Text>
            <Alert status="warning">
              <AlertIcon />
              {t('warning1')}
            </Alert>
            <Alert status="warning">
              <AlertIcon />
              {t('warning2')}
            </Alert>
            <Text>
              <Trans t={t} i18nKey="recognize">
                Staging windows have the word «<code>staging</code>» in the icon
                und the URL.
              </Trans>
            </Text>
            <Button
              colorScheme="yellow"
              size="lg"
              onClick={(e) => {
                // Apparently must be done behind the back of React (at least for development)
                window.location.href = stagingActivationURL;
              }}
            >
              {t('button')}&nbsp;
              <Button
                colorScheme="blackAlpha"
                backgroundColor="yellow"
                textColor="black"
                leftIcon={
                  name ? (
                    <Box
                      boxSize="0.75em"
                      backgroundColor={color}
                      rounded="full"
                    />
                  ) : undefined
                }
              >
                {name ?? ''}
              </Button>
            </Button>
          </Stack>
        </Container>
      </Center>
    );
  } else {
    addToast({
      status: 'error',
      title: t('bad_usage'),
    });
    return <Redirect to="/" />;
  }
}
