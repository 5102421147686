import { gql } from '@apollo/client';
import { CARD_DATA_FRAGMENT } from '../fragments/cardData';

export const CARD_DATA_CHANGED_SUBSCRIPTION = gql`
  subscription cardDataChanged(
    $_id: String!
    $transactionsSkip: Int
    $transactionsLimit: Int
  ) {
    cardDataChanged(_id: $_id) {
      ...CardData
    }
  }

  ${CARD_DATA_FRAGMENT}
`;
