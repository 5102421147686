import {
  Box,
  Stack,
  Heading,
  Button,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Alert,
  AlertIcon,
  useToast,
  HStack,
} from '@chakra-ui/react';
import { CenteredSpinner } from '@companyon/components';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { fromNow } from '@trifence/utilities';
import { useQueryParams } from '@companyon/hooks';
import { useGetRegistrationQuery } from '../graphql/hooks/queries/getRegistration';
import { useActivateDeviceMutation } from '../graphql/hooks/mutations/activateDevice';
import { useStorage } from '@companyon/hooks';
import { AiFillExclamationCircle, AiOutlineArrowLeft } from 'react-icons/ai';

export function Registration() {
  const history = useHistory();
  const queryParams = useQueryParams();
  const addToast = useToast();
  const [, { pickDeviceId }] = useStorage();
  const {
    t,
    i18n: { language: locale },
  } = useTranslation(['landing', 'authorization', '_errors']);

  const registrationId = queryParams.get('id') ?? '';

  const getRegistrationQuery = useGetRegistrationQuery({
    variables: {
      _id: registrationId,
    },
    pollInterval: 0,
  });

  const [activateDevice, activateDeviceMutation] = useActivateDeviceMutation({
    onCompleted: (data: any) => {
      if (data?.activateDevice?.device?._id) {
        // XXX handle bearerToken here as well, if provided
        pickDeviceId(data.activateDevice.device._id);
        history.replace('/');
      }
    },
  });

  async function handleActivateDeviceClick() {
    const { registration } = getRegistrationQuery.data;
    const { device } = registration;
    const { _id: deviceId } = device;

    await activateDevice({
      variables: {
        input: {
          deviceId,
        },
      },
    });
  }
  if (getRegistrationQuery.loading) {
    return <CenteredSpinner />;
  }
  if (getRegistrationQuery.error) {
    addToast({
      status: 'error',
      title: t(`_errors:${getRegistrationQuery.error.message}`),
    });
    return (
      <Stack maxWidth="50rem" alignSelf="center" marginX="auto">
        <Heading as="h1">
          <HStack>
            <AiFillExclamationCircle />
            <Text>{t(`_errors:${getRegistrationQuery.error.message}`)}</Text>
          </HStack>
        </Heading>
        <Button
          iconLeft={<AiOutlineArrowLeft />}
          onClick={() => history.push('/')}
        >
          {t('registration.back')}
        </Button>
      </Stack>
    );
  }

  const {
    registration: { device, expiresAt },
  } = getRegistrationQuery.data;

  const { color, name, role, owner, creation } = {
    color: device.color,
    name: device.name ?? '-',
    role: t(`authorization:roles.long.${device.role}`),
    owner: [device.owner.name.first, device.owner.name.last].join(' '),
    creation: fromNow({ locale, granular: true })(device.createdAt),
  };

  return (
    <Stack marginBottom={16} padding={{ base: 4, md: 8 }} spacing={8}>
      <Heading as="h1" textAlign={{ base: 'center', md: 'left' }} size="2xl">
        {t('registration.heading')}
      </Heading>

      <Table variant="striped">
        <Thead>
          <Tr>
            <Th></Th>
            <Th></Th>
          </Tr>
        </Thead>

        <Tbody>
          <Tr>
            <Td>{t('registration.color')}</Td>
            <Td>
              <Box boxSize={4} rounded="full" backgroundColor={color} />
            </Td>
          </Tr>

          <Tr>
            <Td>{t('registration.name')}</Td>
            <Td>{name}</Td>
          </Tr>

          <Tr>
            <Td>{t('registration.role')}</Td>
            <Td>{role}</Td>
          </Tr>

          <Tr>
            <Td>{t('registration.owner')}</Td>
            <Td>{owner}</Td>
          </Tr>

          <Tr>
            <Td>{t('registration.creation')}</Td>
            <Td>{creation}</Td>
          </Tr>
        </Tbody>
      </Table>

      <Alert status="warning">
        <AlertIcon />
        {t('registration.alert', {
          expiration: fromNow({ locale, granular: true })(expiresAt),
        })}
      </Alert>

      <Button
        colorScheme="brand"
        size="lg"
        isLoading={activateDeviceMutation.loading}
        onClick={handleActivateDeviceClick}
      >
        {t('registration.button')}
      </Button>
    </Stack>
  );
}
