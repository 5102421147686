import { useSubscription } from '@apollo/client';
import { useQuery, QueryHookOptions } from '@companyon/graphql';
import { GET_BUSINESS_QUERY } from '../../schema/queries/getBusiness';
import { BUSINESS_DATA_CHANGED_SUBSCRIPTION } from '../../schema/subscriptions/businessDataChanged';

export function useGetBusinessQuery(options?: QueryHookOptions) {
  const query = useQuery(GET_BUSINESS_QUERY, options);

  useSubscription(BUSINESS_DATA_CHANGED_SUBSCRIPTION, {
    variables: options?.variables,
    skip: options?.skip,
    onSubscriptionData: ({ client, subscriptionData }) => {
      const previousQueryResult = client.readQuery({
        query: GET_BUSINESS_QUERY,
        variables: options?.variables,
      });

      client.writeQuery({
        query: GET_BUSINESS_QUERY,
        variables: options?.variables,
        data: {
          ...previousQueryResult,
          ...subscriptionData.data.businessDataChanged,
        },
      });
    },
  });

  return query;
}
