import { gql } from '@apollo/client';

export const REGISTRATION_DATA_FRAGMENT = gql`
  fragment RegistrationData on Registration {
    _id
    expiresAt
    device {
      _id
      color
      name
      role
      owner {
        name {
          first
          last
        }
      }
      createdAt
    }
  }
`;
