import { selectAppEnv } from '@trifence/utilities';

let appName: string = 'CompanyON';

export function setAppName(name: string) {
  appName = name;
}

export function title() {
  return selectAppEnv([
    `${appName}`,
    `${appName} — Staging`,
    `${appName} — Dev`,
  ]);
}
