import { useEffect, Suspense, useMemo } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider, extendTheme, Stack, useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import { ApolloProvider } from '@apollo/client';
import { useApolloClient } from '@companyon/graphql';
import { match, nowMillis, uniqueBy } from '@trifence/utilities';
import { colors, urls } from '@companyon/constants';
import { errors, i18nBackend as ErrorsBackend } from '@companyon/errors';
import { realTimeBearerTokens, useStorage } from '@companyon/hooks';
import { CenteredSpinner } from '@companyon/components';
import { Page } from './Page';
import { initializeI18n } from './i18n';
import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/800.css';
import '@fontsource/esteban/400.css';

// Error toast throttling
let lastDeviceDeletion: number | undefined;
let lastOtherError: number | undefined;

initializeI18n({
  language: localStorage.getItem('language') ?? 'de',
  backends: [ErrorsBackend, HttpBackend],
});

export function App() {
  const [{ language }] = useStorage();
  const { i18n } = useTranslation('_errors', { useSuspense: false });

  useEffect(() => {
    /* Set HTML lang attribute */
    document.documentElement.setAttribute('lang', language);
    /* Set react-i18next language */
    i18n.changeLanguage(language);
  }, [language, i18n]);

  const theme = useMemo(() => {
    return extendTheme({
      colors,
      fonts: {
        body: 'Esteban',
        heading: 'Montserrat',
      },
      styles: {
        global: {},
      },
      components: {
        Button: {
          baseStyle: { fontFamily: 'Montserrat', fontWeight: 'semibold' },
        },
        Text: { baseStyle: { fontStyle: 'normal' } },
        Heading: { baseStyle: { fontWeight: 300 } },
        Table: {
          sizes: {
            md: { th: { px: '2' }, td: { px: '2' }, caption: { px: '2' } },
          },
        },
      },
      config: {
        initialColorMode: 'dark',
        useSystemColorMode: false,
      },
    });
  }, []);
  return (
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <Suspense fallback={<CenteredSpinner />}>
          <ConfiguredApolloProvider>
            <Stack minHeight="100vh">
              <Page />
            </Stack>
          </ConfiguredApolloProvider>
        </Suspense>
      </ChakraProvider>
    </BrowserRouter>
  );
}

type DBProps = { children: React.ReactElement };

function ConfiguredApolloProvider(props: DBProps) {
  const { children } = props;
  const [{ deviceId, bearerToken }, { deleteDeviceId }] = useStorage();
  const { t } = useTranslation(['_errors', 'landing']);
  const addToast = useToast();

  const apiHttpUrl = useMemo(() => {
    return match(process.env.REACT_APP_ENV)
      .with('staging', () => urls.api.http.staging)
      .with('production', () => urls.api.http.production)
      .otherwise(() => urls.api.http.development);
  }, []);

  const apiWebsocketUrl = useMemo(() => {
    return match(process.env.REACT_APP_ENV)
      .with('staging', () => urls.api.webSocket.staging)
      .with('production', () => urls.api.webSocket.production)
      .otherwise(() => urls.api.webSocket.development);
  }, []);

  const merge = (existing: any, incoming: any, { args }: any) => {
    const { skip } = args;

    const merged = existing ? existing.slice(0) : [];
    const updates = incoming ?? [];

    for (let i = 0; i < updates.length; ++i) {
      merged[skip + i] = updates[i];
    }

    return uniqueBy(merged, '__ref');
  };

  const token =
    deviceId && bearerToken ? `${deviceId}/${bearerToken}` : deviceId;
  const apolloClient = useApolloClient({
    httpLinkUrl: `${apiHttpUrl}/graphql`,
    websocketLinkUrl: apiWebsocketUrl,
    bearerToken: token,
    cacheConfig: {
      typePolicies: {
        User: {
          fields: {
            devices: {
              keyArgs: false,
              merge,
            },
          },
        },
        Business: {
          fields: {
            transactions: {
              keyArgs: false,
              merge,
            },
            users: {
              keyArgs: false,
              merge,
            },
          },
        },
        Card: {
          fields: {
            transactions: {
              keyArgs: false,
              merge,
            },
          },
        },
      },
    },
    onGraphQLErrors: (graphQLErrors, connectionAuthorization) => {
      graphQLErrors.forEach((graphQLError) => {
        const { message, path } = graphQLError;
        if (message === errors.authorization.HEADER_NOT_PROVIDED) {
          // setDeviceBearerToken and ping are fired even for no deviceId. Ignore them.
          // For all others, just log them (what else to do?)

          // TODO: Make sure that ping/setDeviceBearerToken are only called after
          // the deviceId has been set. Not trivial, given the React limitations.
          if (
            !path?.includes('ping') &&
            !path?.includes('setDeviceBearerToken')
          ) {
            addToast({
              status: 'error',
              title: t(message) + ' ' + path?.join(' '),
            });
          }
        } else if (message === errors.authorization.DEVICE_NOT_AUTHORIZED) {
          // Request authorization error: no longer use this device
          if (
            deviceId &&
            connectionAuthorization &&
            connectionAuthorization.startsWith(deviceId)
          ) {
            deleteDeviceId(deviceId);
            if (
              !lastDeviceDeletion ||
              lastDeviceDeletion + 10000 < nowMillis()
            ) {
              lastDeviceDeletion = nowMillis();
              addToast({
                status: 'error',
                title: `${t('landing:errors.deletingDevice')} (${t(message)})`,
                duration: 15000,
              });
            }
          } else {
            console.log(
              `Ignoring ${message} for connection with ${connectionAuthorization}, when already changed to ${deviceId}`,
            );
          }
        } else if (message === errors.authorization.BEARER_TOKEN_REQUIRED) {
          /* Request authorization error: require a bearerToken.
           * Maybe another tab has already acquired it in the meantime,
           * so, check for this before deleting the device. */
          if (
            deviceId &&
            connectionAuthorization &&
            connectionAuthorization.startsWith(deviceId)
          ) {
            if (bearerToken || realTimeBearerTokens()[deviceId]) {
              /* Reload to re-establish the websocket with the
               * correct credentials (and navigate to "/", as
               * the current page might be invalid in the new context) */
              if (window.location.pathname === '/') {
                window.location.reload();
              } else {
                window.location.assign('/');
              }
            } else {
              /* Abandon all hope for that device ID:
               * A different device has acknowledged the bearerToken. */
              addToast({
                status: 'error',
                title: `${t('landing:errors.deletingDevice')} (${t(message)})`,
                duration: 15000,
              });
              deleteDeviceId(deviceId);
            }
          } else {
            console.log(
              `Ignoring ${message} for connection with ${connectionAuthorization}, when already changed to ${deviceId}`,
            );
          }
        } else if (
          message === errors.notFound.DEVICE_NOT_FOUND ||
          message === errors.notFound.DEVICE_DELETED
        ) {
          // Ignore, unless it is in response to `setBearerToken` or 'ping'.
          // There, we know that it applies to "our" device.
          // For `device` queries (e.g., from the DeviceSwitcher), we cannot
          // reliable determine the ID that was queried for.
          if (
            (path?.includes('setDeviceBearerToken') ||
              path?.includes('ping')) &&
            deviceId &&
            connectionAuthorization &&
            connectionAuthorization.startsWith(deviceId)
          ) {
            addToast({
              status: 'error',
              title: `${t('landing:errors.deletingDevice')} (${t(message)})`,
              duration: 15000,
            });
            deleteDeviceId(deviceId);
          } else {
            console.log(`Ignoring ${message}`);
          }
        } else if (message === errors.forbidden.INSUFFICIENT_PRIVILEGES) {
          // This error will be acted upon by <User>, so reporting it here
          // is more confusing to the user.
          if (!path?.includes('user')) {
            if (!lastOtherError || lastOtherError + 1000 < nowMillis()) {
              lastOtherError = nowMillis();
              addToast({
                status: 'error',
                title: t(message),
              });
            }
          }
        } else {
          if (!lastOtherError || lastOtherError + 1000 < nowMillis()) {
            lastOtherError = nowMillis();
            addToast({
              status: 'error',
              title: t(message),
            });
          }
        }
      });
    },
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}
