import { gql } from '@apollo/client';
import { BUSINESS_TRANSACTIONS_SUMMARY_DATA_FRAGMENT } from '../fragments/businessTransactionsSummaryData';

export const GET_BUSINESS_TRANSACTIONS_SUMMARY_QUERY = gql`
  query getBusinessTransactionsSummary(
    $businessId: String!
    $createdAfter: Float!
    $createdBefore: Float!
  ) {
    businessTransactionsSummary: getBusinessTransactionsSummary(
      businessId: $businessId
      createdAfter: $createdAfter
      createdBefore: $createdBefore
    ) {
      ...BusinessTransactionsSummaryData
    }
  }

  ${BUSINESS_TRANSACTIONS_SUMMARY_DATA_FRAGMENT}
`;
