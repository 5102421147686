import { Button, Flex, Spacer, useBreakpointValue } from '@chakra-ui/react';
import { TransactionList, TransactionListProps } from '@companyon/components';
import {
  nowMillis,
  startOf,
  endOf,
  monthName,
  yearNum,
  formatTimestamp,
} from '@trifence/utilities';
import { TransactionsSummaryModal } from './TransactionsSummaryModal';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoCalculator } from 'react-icons/io5';

type BusinessTransactionListProps = Omit<
  TransactionListProps,
  'dailyButtonProps' | 'dailyButtonFunction'
> & {
  locale: string;
};

export function BusinessTransactionList(props: BusinessTransactionListProps) {
  const { locale, businessId, perspective, ...rest } = props;
  const { t } = useTranslation('card');
  const shortMonth = useBreakpointValue({ base: true, md: false });
  const overview = t('transactions.overview');
  const maybeOverview = useBreakpointValue({ base: '', lg: overview + ' ' });
  const buttonWidth = '13em';
  const maybeButtonWidth = useBreakpointValue({
    base: undefined,
    sm: '6em',
    md: '8em',
    lg: buttonWidth,
  });

  const [selectedSection, setSelectedSection] = useState<
    number | 'thisMonth' | 'prevMonth' | 'thisYear' | 'prevYear' | null
  >(null);

  const transactionsSummaryBounds = useMemo(() => {
    if (!selectedSection) {
      return null;
    } else if (selectedSection === 'thisMonth') {
      const now = nowMillis();
      return {
        createdAfter: startOf('month')(now),
        createdBefore: endOf('month')(now),
        title:
          t('transactions.overview') +
          ' ' +
          monthName(locale, now, shortMonth) +
          ' ' +
          yearNum(now),
      };
    } else if (selectedSection === 'prevMonth') {
      const startOfThisMonth = startOf('month')(nowMillis());
      return {
        createdAfter: startOf('month')(startOfThisMonth - 1),
        createdBefore: startOfThisMonth,
        title:
          t('transactions.overview') +
          ' ' +
          monthName(locale, startOfThisMonth - 1, shortMonth) +
          ' ' +
          yearNum(startOfThisMonth - 1),
      };
    } else if (selectedSection === 'thisYear') {
      const now = nowMillis();
      return {
        createdAfter: startOf('year')(now),
        createdBefore: endOf('year')(now),
        title: t('transactions.overview') + ' ' + yearNum(now),
      };
    } else if (selectedSection === 'prevYear') {
      const startOfThisYear = startOf('year')(nowMillis());
      return {
        createdAfter: startOf('year')(startOfThisYear - 1),
        createdBefore: startOfThisYear,
        title: t('transactions.overview') + ' ' + yearNum(startOfThisYear - 1),
      };
    } else {
      return {
        createdAfter: startOf('day')(selectedSection),
        createdBefore: endOf('day')(selectedSection),
        title: formatTimestamp({ format: 'LL', locale })(selectedSection),
      };
    }
  }, [selectedSection, locale, t, shortMonth]);

  return (
    <TransactionList
      perspective={perspective}
      locale={locale}
      dailyButtonProps={{ minWidth: maybeButtonWidth }}
      dailyButtonFunction={setSelectedSection}
      {...rest}
    >
      {perspective === 'BUSINESS' ? (
        <Flex overflow="hidden">
          <Button
            minWidth={maybeButtonWidth}
            colorScheme="brand"
            rightIcon={<IoCalculator />}
            onClick={() => {
              setSelectedSection('thisMonth');
            }}
          >
            {maybeOverview + monthName(locale, nowMillis(), shortMonth)}
          </Button>
          <Spacer minWidth="0.5em" />
          <Button
            minWidth={maybeButtonWidth}
            colorScheme="brand"
            rightIcon={<IoCalculator />}
            onClick={() => {
              setSelectedSection('prevMonth');
            }}
          >
            {maybeOverview +
              monthName(
                locale,
                startOf('month')(startOf('month')(nowMillis()) - 1),
                shortMonth,
              )}
          </Button>
          <Spacer minWidth="0.5em" />
          <Button
            minWidth={maybeButtonWidth}
            colorScheme="brand"
            rightIcon={<IoCalculator />}
            onClick={() => {
              setSelectedSection('thisYear');
            }}
          >
            {maybeOverview + yearNum(nowMillis())}
          </Button>
          <Spacer minWidth="0.5em" />
          <Button
            minWidth={maybeButtonWidth}
            colorScheme="brand"
            rightIcon={<IoCalculator />}
            onClick={() => {
              setSelectedSection('prevYear');
            }}
          >
            {maybeOverview + yearNum(nowMillis(), -1)}
          </Button>
        </Flex>
      ) : undefined}
      {perspective === 'BUSINESS' && businessId && transactionsSummaryBounds ? (
        <TransactionsSummaryModal
          businessId={businessId}
          createdAfter={transactionsSummaryBounds.createdAfter}
          createdBefore={transactionsSummaryBounds.createdBefore}
          title={transactionsSummaryBounds.title}
          onClose={() => {
            setSelectedSection(null);
          }}
        />
      ) : null}
    </TransactionList>
  );
}
