import { useState, useMemo } from 'react';
import useInterval from 'react-use/lib/useInterval';
import { sort } from '@trifence/utilities';
import { times } from '@companyon/constants';

type Transaction = any;

type State = Transaction & {
  isCancellable: boolean;
  cancellationExpiresAfter: number;
};

export function useLastTransaction(transactions: Transaction[]): State {
  const [cancellationExpiresAfter, setCancellationExpiresAfter] = useState(0);

  const lastTransaction = useMemo(() => {
    if (!transactions || transactions.length === 0) {
      return null;
    }

    const [lastTransaction] = sort<Transaction>(
      (transaction1, transaction2) => {
        return transaction2.createdAt - transaction1.createdAt;
      },
    )(transactions);

    return lastTransaction;
  }, [transactions]);

  useInterval(() => {
    setCancellationExpiresAfter(calculateCancellationExpiry(lastTransaction));
  }, times.ONE_SECOND_IN_MILLIS);

  const state = {
    ...lastTransaction,
    isCancellable: cancellationExpiresAfter > 0,
    cancellationExpiresAfter,
  };

  return state;
}

function calculateCancellationExpiry(transaction: Transaction | null): number {
  if (!transaction || transaction.isCancelled) {
    return 0;
  }

  return Math.max(0, transaction.cancellationExpiresAt - Date.now()) / 1000;
}
