import {
  Box,
  Flex,
  Stack,
  Heading,
  Button,
  FlexProps,
  useColorMode,
  useColorModeValue,
  LinkOverlay,
} from '@chakra-ui/react';
import { FAB } from '@companyon/components';
import { RiQrCodeLine } from 'react-icons/ri';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AnimatedCard } from '../components/AnimatedCard';
import { urls } from '@companyon/constants/dist';

type Props = FlexProps;

export function Landing(props: Props) {
  const { ...rest } = props;

  const { t } = useTranslation('landing');
  const { colorMode } = useColorMode();
  const history = useHistory();

  const headingGradient = useColorModeValue(
    'linear(to-r, brand.600, brand.400)',
    'linear(to-l, brand.600, brand.400)',
  );

  const primaryButtonBorderColor = useColorModeValue('white', 'brand');

  function handleScanClick() {
    history.push('/scan');
  }

  return (
    <>
      <Flex
        flex={1}
        direction={{ base: 'column', md: 'row-reverse' }}
        justify={{ base: 'center', md: 'space-around', xl: 'space-between' }}
        align="center"
        paddingX={{ base: 8, md: 18, lg: 32 }}
        paddingY={16}
        zIndex={2}
        {...rest}
      >
        <AnimatedCard
          width={{ base: '80%', sm: '60%', md: '45%', lg: '40%', xl: '30%' }}
          marginBottom={{ base: 12, md: 0 }}
        />

        <Stack
          width={{ base: '80%', md: '40%' }}
          align={{ base: 'center', md: 'start' }}
          spacing={6}
        >
          <Heading
            as="h1"
            textAlign={{ base: 'center', md: 'left' }}
            fontSize={{ base: '5xl', md: '6xl' }}
            bgGradient={headingGradient}
            bgClip="text"
          >
            {t('heading')}
          </Heading>

          <Heading
            as="h2"
            textAlign={{ base: 'center', md: 'left' }}
            size="md"
            fontWeight="normal"
            opacity={0.8}
            lineHeight={1.5}
          >
            {t('subheading')}
          </Heading>

          <Stack direction={{ base: 'column', sm: 'row' }}>
            <Button
              as={RouterLink}
              to="/scan"
              size="lg"
              colorScheme="brand"
              borderColor={primaryButtonBorderColor}
              borderWidth={2}
              leftIcon={<RiQrCodeLine />}
            >
              {t('scanCard')}
            </Button>

            <Button
              as={LinkOverlay}
              href={urls.web.customer.production}
              variant="outline"
              size="lg"
              colorScheme="brand"
              backgroundColor={primaryButtonBorderColor}
              borderWidth={2}
            >
              {t('learnMore')}
            </Button>
          </Stack>
        </Stack>
      </Flex>

      {colorMode === 'light' ? (
        <Box
          display={{ base: 'none', md: 'block' }}
          position="absolute"
          width="full"
          bottom={0}
          left={0}
          zIndex={1}
          fill="brand.500"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path d="M0,192L1440,64L1440,320L0,320Z"></path>
          </svg>
        </Box>
      ) : null}

      <FAB
        colorScheme="brand"
        aria-label="Scan card"
        icon={<RiQrCodeLine size={30} />}
        onClick={handleScanClick}
      />
    </>
  );
}
