import { gql } from '@apollo/client';
import { BUSINESS_DATA_FRAGMENT } from '../fragments/businessData';

export const BUSINESS_DATA_CHANGED_SUBSCRIPTION = gql`
  subscription businessDataChanged(
    $_id: String!
    $transactionsSkip: Int
    $transactionsLimit: Int
    $usersSkip: Int
    $usersLimit: Int
  ) {
    businessDataChanged(_id: $_id) {
      ...BusinessData
    }
  }

  ${BUSINESS_DATA_FRAGMENT}
`;
