import { gql } from '@apollo/client';
import { DEVICE_DATA_FRAGMENT_DEEP } from '../fragments/deviceData';

export const GET_DEVICE_QUERY = gql`
  query getDevice($_id: String!) {
    device: getDevice(_id: $_id) {
      ...DeviceDataDeep
    }
  }

  ${DEVICE_DATA_FRAGMENT_DEEP}
`;
