import { gql } from '@apollo/client';
import { DEVICE_DATA_FRAGMENT_DEEP } from '../fragments/deviceData';

export const UPDATE_BUSINESS_DEVICE_MUTATION = gql`
  mutation updateBusinessDevice($input: UpdateBusinessDeviceMutationInput!) {
    updateBusinessDevice(input: $input) {
      device {
        ...DeviceDataDeep
      }
    }
  }

  ${DEVICE_DATA_FRAGMENT_DEEP}
`;
