import { useMutation, MutationHookOptions } from '@companyon/graphql';
import { SET_DEVICE_BEARER_TOKEN_MUTATION } from '../../schema/mutations/setDeviceBearerToken';
import { PING_MUTATION } from '../../schema/mutations/ping';

type SetDeviceBearerTokenMutationOptions = MutationHookOptions & {
  skip?: boolean;
};

export function useSetDeviceBearerTokenMutation(
  options?: SetDeviceBearerTokenMutationOptions,
) {
  const { skip, ...rest } = options ?? {};
  return useMutation(
    skip ? PING_MUTATION : SET_DEVICE_BEARER_TOKEN_MUTATION,
    rest,
  );
}
