import { gql } from '@apollo/client';
import { DEVICE_DATA_FRAGMENT_DEEP } from '../fragments/deviceData';

export const DELETE_DEVICE_MUTATION = gql`
  mutation deleteDevice($input: DeleteDeviceMutationInput!) {
    deleteDevice(input: $input) {
      device {
        ...DeviceDataDeep
      }
    }
  }

  ${DEVICE_DATA_FRAGMENT_DEEP}
`;
