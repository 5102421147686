import { useState, useEffect } from 'react';
import _useInterval from 'react-use/lib/useInterval';

export function useInterval(
  callback: (counter: number) => void,
  delay?: number | null | undefined,
) {
  const [counter, setCounter] = useState(0);

  _useInterval(() => {
    setCounter((counter) => counter + 1);
  }, delay);

  useEffect(() => {
    callback(counter);
  }, [callback, counter]);
}
