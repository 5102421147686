import { gql } from '@apollo/client';

export const PING_MUTATION = gql`
  mutation ping {
    ping {
      ping {
        ping
        pingedAt
      }
    }
  }
`;
