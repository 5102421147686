import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import ChainedBackend from 'i18next-chained-backend';

type Options = {
  language: string;
  backends: any[];
};

export function initializeI18n({ language, backends }: Options) {
  const options = {
    lng: language,
    interpolation: {
      escapeValue: false,
    },
  };

  const backend: object = {
    backends,
    backendOptions: [options, options],
  };

  i18next
    .use(ChainedBackend)
    .use(initReactI18next)
    .init({
      ...options,
      backend,
      fallbackLng: 'de',
      ns: [],
    });
}
