import {
  Box,
  Stack,
  Text,
  Link,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  useBreakpointValue,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { Trans as Translation, useTranslation } from 'react-i18next';
import { Emoticon } from '@companyon/components';

type Props = {
  topic: 'BALANCE' | 'AVAILABLE' | null;
  onClose?: () => void;
};

export function ExplanationDrawer(props: Props) {
  const { topic, onClose } = props;

  const { t } = useTranslation('tips');
  const placement = useBreakpointValue<'top' | 'bottom' | 'right' | 'left'>({
    base: 'bottom',
    md: 'right',
  });

  function handleClose() {
    onClose?.();
  }

  return (
    <Drawer placement={placement} isOpen={Boolean(topic)} onClose={handleClose}>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />

          <DrawerHeader borderBottomWidth="1px">
            {t(`${topic}.title`)} <Emoticon symbol="💡" label="Bulb" />
          </DrawerHeader>

          <DrawerBody>
            <Stack spacing={4}>
              <Text>{t(`${topic}.firstParagraph`)}</Text>

              <Box>
                <Translation
                  t={t}
                  i18nKey={`${topic}.secondParagraph`}
                  values={{
                    email: 'info@companyon.ch',
                    phone: '+41 52 511 0365',
                  }}
                  components={[
                    <Link
                      color="brand.500"
                      isExternal={true}
                      href="mailto:info@companyon.ch?subject=Frage zur Abrechnung"
                    />,
                    <Link
                      color="brand.500"
                      isExternal={true}
                      href="tel:+41525110365"
                    />,
                  ]}
                />
              </Box>

              <Button
                as={RouterLink}
                to="/transactions"
                variant="ghost"
                colorScheme="brand"
              >
                {t(`${topic}.button`)}
              </Button>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
}
