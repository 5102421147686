import { useState } from 'react';
import useInterval from 'react-use/lib/useInterval';
import { match } from '@trifence/utilities';
import { times } from '@companyon/constants';

type TimeOfDay = 'morning' | 'afternoon' | 'evening';

export function useTimeOfDay(): TimeOfDay {
  const [timeOfDay, setTimeOfDay] = useState<TimeOfDay>(
    calculateTimeOfDay(Date.now()),
  );

  useInterval(() => {
    setTimeOfDay(calculateTimeOfDay(Date.now()));
  }, times.TIME_OF_DAY_GRANULARITY);

  return timeOfDay;
}

function calculateTimeOfDay(millis: number): TimeOfDay {
  const hours = new Date(millis).getHours();

  return match<number, TimeOfDay>(hours)
    .when(
      (hours) => hours >= 5 && hours < 11,
      () => 'morning',
    )
    .when(
      (hours) => hours >= 11 && hours < 18,
      () => 'afternoon',
    )
    .otherwise(() => 'evening');
}
