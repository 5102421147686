import {
  Box,
  VStack,
  HStack,
  SimpleGrid,
  Text,
  Avatar,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';
import { CenteredSpinner } from '@companyon/components';
import { useTranslation } from 'react-i18next';
import { useGetDevicesQuery } from '../graphql/hooks/queries/getDevices';
import { useStorage } from '@companyon/hooks';

type Device = any;

type Props = {
  isOpen: boolean;
  onClose?: () => void;
};

export function DeviceSwitcher(props: Props) {
  const { isOpen, onClose } = props;

  const [{ deviceIds }, { pickDeviceId }] = useStorage();
  const { t } = useTranslation('landing');

  const getDevicesQuery = useGetDevicesQuery({
    skip: deviceIds.length === 0,
    variables: {
      _ids: deviceIds,
    },
  });

  const { devices } = {
    devices: getDevicesQuery.data?.devices ?? [],
  };

  function handleClose() {
    onClose?.();
  }

  return (
    <Modal isCentered={false} isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>{t('navbar.deviceSwitcher')}</ModalHeader>

        <ModalCloseButton />

        <ModalBody padding={16}>
          <SimpleGrid minChildWidth="6em" spacing={8}>
            {(() => {
              if (getDevicesQuery.loading) {
                return <CenteredSpinner />;
              }

              return devices.map((device: Device) => (
                <VStack
                  spacing={4}
                  cursor="pointer"
                  key={device._id}
                  onClick={() => {
                    pickDeviceId(device._id);
                    handleClose();
                  }}
                >
                  <Avatar
                    src={device.owner.employer.logoUrl}
                    name={device.owner.employer.name}
                    size="lg"
                  />

                  <HStack>
                    <Box
                      boxSize="1em"
                      backgroundColor={device.color}
                      rounded="full"
                    />

                    {device.name ? (
                      <Text size="lg" maxWidth={24} isTruncated={true}>
                        {device.name}
                      </Text>
                    ) : null}
                  </HStack>
                </VStack>
              ));
            })()}
          </SimpleGrid>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
