import {
  VStack,
  HStack,
  Text,
  Image,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { Alerter } from '@companyon/components';
import { useTranslation } from 'react-i18next';

type Props = {
  registrationUrl: string;
  dataUrl: string;
  onClose: () => void;
};

export function QRCodeModal(props: Props) {
  const { registrationUrl, dataUrl, onClose } = props;
  const { t } = useTranslation('qrCodeModal');
  const localCreationModal = useDisclosure();

  return (
    <Modal isCentered={false} isOpen={true} onClose={onClose}>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>{t('title')}</ModalHeader>

        <ModalCloseButton />

        <ModalBody paddingX={12} paddingY={16}>
          <VStack spacing={8}>
            <Text fontSize="xl" fontWeight="500" textAlign="center">
              {t('heading')}
            </Text>

            <Image
              src={dataUrl}
              boxSize="12em"
              alt="QR code"
              onClick={localCreationModal.onOpen}
            />
            <Alerter
              headerText={t('local.header')}
              cancelText={t('local.cancel')}
              confirmationText={t('local.confirm')}
              confirmationColorScheme="green"
              disclosure={localCreationModal}
              onConfirmation={() => {
                window.location.assign(registrationUrl);
              }}
            >
              {t('local.explanation')}
            </Alerter>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <HStack>
            <Button onClick={onClose}>{t('cancel')}</Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
