import { gql } from '@apollo/client';
import { DEVICE_DATA_FRAGMENT_DEEP } from '../fragments/deviceData';

export const GET_DEVICES_QUERY = gql`
  query getDevices($_ids: [String!]!) {
    devices: getDevices(_ids: $_ids) {
      ...DeviceDataDeep
    }
  }

  ${DEVICE_DATA_FRAGMENT_DEEP}
`;
