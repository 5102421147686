import { IconButton, IconButtonProps } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { IoMdArrowBack } from 'react-icons/io';

type Props = Omit<IconButtonProps, 'aria-label'>;

export function BackButton(props: Props) {
  const { ...rest } = props;

  const history = useHistory();

  function handleClick() {
    history.goBack();
  }

  return (
    <IconButton
      variant="ghost"
      icon={<IoMdArrowBack size={24} />}
      aria-label="Go back"
      onClick={handleClick}
      {...rest}
    />
  );
}
