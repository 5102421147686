import { gql } from '@apollo/client';
import { DEVICE_DATA_FRAGMENT_DEEP } from '../fragments/deviceData';

export const REGISTER_BUSINESS_DEVICE_MUTATION = gql`
  mutation registerBusinessDevice(
    $input: RegisterBusinessDeviceMutationInput!
  ) {
    registerBusinessDevice(input: $input) {
      device {
        ...DeviceDataDeep
      }
    }
  }

  ${DEVICE_DATA_FRAGMENT_DEEP}
`;
