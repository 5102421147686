import { Stack, HStack, Heading, VStack } from '@chakra-ui/react';
import {
  CenteredSpinner,
  ErrorIndicator,
  BackToTop,
} from '@companyon/components';
import { useTranslation } from 'react-i18next';
import { pagination } from '@companyon/constants';
import { BackButton } from '../components/BackButton';
import { useGetDeviceQuery } from '../graphql/hooks/queries/getDevice';
import { useGetBusinessQuery } from '../graphql/hooks/queries/getBusiness';
import { useStorage } from '@companyon/hooks';
import { BusinessTransactionList } from '../components/BusinessTransactionList';
import placeholderAsset from '../assets/images/placeholder.png';
import cancelAsset from '../assets/images/cancel.svg';
import { useEffect } from 'react';
import { IoBagHandle } from 'react-icons/io5';

export function Transactions() {
  const [{ deviceId }] = useStorage();
  const {
    t,
    i18n: { language: locale },
  } = useTranslation(['card', 'authorization']);

  const getDeviceQuery = useGetDeviceQuery({
    variables: {
      _id: deviceId,
    },
  });

  const getBusinessQuery = useGetBusinessQuery({
    skip: !getDeviceQuery.data?.device.owner.employerId,
    variables: {
      _id: getDeviceQuery.data?.device.owner.employerId ?? '',
      transactionsSkip: 0,
      transactionsLimit: pagination.PAGE_SIZE,
    },
  });

  const {
    businessId,
    transactions,
    transactionsCount,
    canViewBusinessTransactions,
    canViewBusinessTransactionCount,
  } = {
    businessId: getBusinessQuery.data?.business._id ?? '',
    transactions: getBusinessQuery.data?.business.transactions ?? [],
    transactionsCount: getBusinessQuery.data?.business.transactionsCount ?? 0,
    canViewBusinessTransactions:
      getDeviceQuery.data?.device.permissions.viewBusinessTransactions ?? true,
    canViewBusinessTransactionCount:
      getDeviceQuery.data?.device.permissions.viewBusinessTransactionCount ??
      true,
  };

  const canLoadMoreTransactions = transactions.length < transactionsCount;

  function handleLoadMoreTransactions() {
    getBusinessQuery.fetchMore({
      variables: {
        transactionsSkip: transactions.length,
        transactionsLimit: pagination.PAGE_SIZE,
      },
    });
  }

  function maybeLoadMoreTransactions() {
    if (transactions.length === 0 && canLoadMoreTransactions) {
      handleLoadMoreTransactions();
    }
  }
  useEffect(maybeLoadMoreTransactions);

  if (getBusinessQuery.loading) {
    return <CenteredSpinner />;
  }

  if (!canViewBusinessTransactions || !canViewBusinessTransactionCount) {
    return (
      <ErrorIndicator
        heading={t('authorization:error.heading')}
        subheading={t('authorization:error.subheading')}
      />
    );
  }

  if (transactions.length === 0 && canLoadMoreTransactions) {
    return <CenteredSpinner />;
  }

  return (
    <Stack
      padding={{ base: 4, md: 8 }}
      paddingBottom={16}
      spacing={8}
      width="100%"
      maxWidth="5xl"
      alignSelf="center"
    >
      <HStack>
        <BackButton />

        <Heading as="h1" size="2xl">
          {t('transactions.heading')}
        </Heading>
      </HStack>
      {transactionsCount === 0 ? (
        <VStack align="center">
          <IoBagHandle size={64} />
          <Heading
            as="h2"
            textAlign="left"
            fontSize={{ base: 'xl', md: '2xl' }}
          >
            {t('noTransactions')}
          </Heading>
        </VStack>
      ) : (
        <>
          <BusinessTransactionList
            transactions={transactions}
            businessId={businessId}
            perspective="BUSINESS"
            locale={locale}
            canLoadMore={canLoadMoreTransactions}
            onLoadMore={handleLoadMoreTransactions}
            placeholderAsset={placeholderAsset}
            cancelAsset={cancelAsset}
          />

          {canLoadMoreTransactions ? null : (
            <BackToTop alignSelf="center">{t('backToTop')}</BackToTop>
          )}
        </>
      )}
    </Stack>
  );
}
