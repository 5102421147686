import { useSubscription } from '@apollo/client';
import { useQuery, QueryHookOptions } from '@companyon/graphql';
import { GET_DEVICE_QUERY } from '../../schema/queries/getDevice';
import { DEVICE_DATA_CHANGED_SUBSCRIPTION } from '../../schema/subscriptions/deviceDataChanged';

export function useGetDeviceQuery(options?: QueryHookOptions) {
  const query = useQuery(GET_DEVICE_QUERY, options);

  useSubscription(DEVICE_DATA_CHANGED_SUBSCRIPTION, {
    variables: options?.variables,
    skip: options?.skip,
    onSubscriptionData: ({ client, subscriptionData }) => {
      const previousQueryResult = client.readQuery({
        query: GET_DEVICE_QUERY,
        variables: options?.variables,
      });
      const device = {
        ...previousQueryResult?.device,
        ...subscriptionData.data.deviceDataChanged,
      };

      client.writeQuery({
        query: GET_DEVICE_QUERY,
        variables: options?.variables,
        data: { device },
      });
    },
  });

  return query;
}
