import {
  HStack,
  Image,
  Button,
  useDisclosure,
  useColorModeValue,
  ButtonProps,
} from '@chakra-ui/react';
import { LanguageSwitcher, NavigationBar } from '@companyon/components';
import { IoIosCloseCircle } from 'react-icons/io';
import { MdHelp } from 'react-icons/md';
import { RiQrCodeLine } from 'react-icons/ri';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLocation } from '@companyon/hooks';
import { DeviceSwitcher } from '../components/DeviceSwitcher';
import { TestNotice } from '../components/TestNotice';

/* TODO: Import conditionally/lazily */
import logoProductionAsset from '../assets/images/companyon.svg';
import logoStagingAsset from '../assets/images/logo-staging.svg';
import { DeviceSwitcherButton } from '../components/DeviceSwitcherButton';
import { ErrorBoundary } from './ErrorBoundary';

export function Header() {
  const history = useHistory();
  const location = useLocation();

  const { t } = useTranslation('landing');
  const { isOpen, onToggle, onClose } = useDisclosure();
  const deviceSwitcherModal = useDisclosure();

  const boxShadow = useColorModeValue('md', 'none');
  const buttonBackgroundOnHover = useColorModeValue('gray.100', 'gray.700');
  const menuTextColor = useColorModeValue('brand.700', 'white');
  const regularButtonProps: ButtonProps = {
    variant: 'outline',
    borderWidth: 2,
    borderColor: 'whiteAlpha.800',
    color: 'whiteAlpha.800',
    paddingX: 2,
    margin: 2,
    _hover: {
      background: [
        'brand.700',
        'brand.700',
        buttonBackgroundOnHover,
        buttonBackgroundOnHover,
      ],
    },
  };

  const isScanning = location.pathname === '/scan';
  const logoAsset =
    process.env.REACT_APP_ENV === 'production'
      ? logoProductionAsset
      : logoStagingAsset;

  function handleScanClick() {
    onClose();
    if (isScanning) {
      return history.goBack();
    }
    return history.push('/scan');
  }

  return (
    <>
      <NavigationBar
        position="sticky"
        top="0"
        zIndex={999}
        background={['brand.500', 'brand.500', 'gray.800', 'gray.800']}
        color={['white', 'white', 'brand.700', 'brand.700']}
        logo={
          <HStack>
            <Link to="/">
              <Image width={{ base: '25px', sm: '40px' }} src={logoAsset} />
            </Link>
            <TestNotice />
          </HStack>
        }
        switcher={
          <ErrorBoundary>
            <DeviceSwitcherButton
              marginX={4}
              onDeviceSwitcherOpen={deviceSwitcherModal.onOpen}
              onMenuClose={onClose}
              {...regularButtonProps}
              maxWidth="10em"
            />
          </ErrorBoundary>
        }
        boxShadow={boxShadow}
        isOpen={isOpen}
        onToggle={onToggle}
        onClose={onClose}
        paddingX={{ base: 4, lg: 6, xl: 8 }}
        paddingY={{ base: 2, lg: 4, xl: 6 }}
      >
        <Button
          as={Link}
          to="/help"
          leftIcon={<MdHelp />}
          onClick={onClose}
          {...regularButtonProps}
        >
          {t('navbar.help')}
        </Button>

        <LanguageSwitcher
          menuTextColor={menuTextColor}
          onMenuClose={onClose}
          {...regularButtonProps}
        />

        <Button
          alignSelf="center"
          background={['white', 'white', 'brand.500', 'brand.500']}
          color={['brand.500', 'brand.500', 'white', 'white']}
          _hover={{
            background: ['brand.100', 'brand.100', 'brand.600', 'brand.600'],
          }}
          leftIcon={isScanning ? <IoIosCloseCircle /> : <RiQrCodeLine />}
          onClick={handleScanClick}
          margin={regularButtonProps.margin}
        >
          {isScanning ? t('navbar.cancel') : t('navbar.scan')}
        </Button>
      </NavigationBar>

      <ErrorBoundary>
        <DeviceSwitcher
          isOpen={deviceSwitcherModal.isOpen}
          onClose={deviceSwitcherModal.onClose}
        />
      </ErrorBoundary>
    </>
  );
}
