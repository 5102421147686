import { useState } from 'react';
import {
  Stack,
  HStack,
  Heading,
  IconButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {
  Alerter,
  CenteredSpinner,
  ErrorIndicator,
  FAB,
} from '@companyon/components';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { IoMdTrash } from 'react-icons/io';
import { FaMobileAlt } from 'react-icons/fa';
import { IoAddSharp } from 'react-icons/io5';
import { MdInfoOutline } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { Trans as Translation, useTranslation } from 'react-i18next';
import { pagination } from '@companyon/constants';
import { BackButton } from '../components/BackButton';
import { UserCreationModal } from '../components/UserCreationModal';
import { InfiniteScrollList } from '@companyon/components';
import { useGetDeviceQuery } from '../graphql/hooks/queries/getDevice';
import { useGetBusinessQuery } from '../graphql/hooks/queries/getBusiness';
import { useDeleteBusinessUserMutation } from '../graphql/hooks/mutations/deleteBusinessUser';
import { useStorage } from '@companyon/hooks';

type User = any;

export function Users() {
  const history = useHistory();
  const [{ deviceId }] = useStorage();
  const { t } = useTranslation(['users', 'authorization']);
  const userCreationModal = useDisclosure();
  const addToast = useToast();

  const getDeviceQuery = useGetDeviceQuery({
    variables: {
      _id: deviceId,
    },
  });

  const getBusinessQuery = useGetBusinessQuery({
    skip: !getDeviceQuery.data?.device.owner.employerId,
    variables: {
      _id: getDeviceQuery.data?.device.owner.employerId ?? '',
      usersSkip: 0,
      usersLimit: pagination.PAGE_SIZE,
    },
  });

  const [deleteBusinessUser, deleteBusinessUserMutation] =
    useDeleteBusinessUserMutation({
      onCompleted: (data: any) => {
        if (data?.deleteBusinessUser) {
          addToast({
            status: 'success',
            title: t('toast.userDeleted'),
          });
        }
      },
    });

  const [selectedUserForDeletion, setSelectedUserForDeletion] =
    useState<User>(null);

  function closeUserDeletionDialog() {
    setSelectedUserForDeletion(null);
  }

  async function handleUserDeletionConfirmation() {
    await deleteBusinessUser({
      variables: {
        input: {
          userId: selectedUserForDeletion?._id,
        },
      },
    });

    closeUserDeletionDialog();
  }

  const {
    users,
    usersCount,
    canCreateBusinessUsers,
    canViewBusinessUsers,
    canViewBusinessUserCount,
  } = {
    users: getBusinessQuery.data?.business.users ?? [],
    usersCount: getBusinessQuery.data?.business.usersCount ?? 0,
    canCreateBusinessUsers:
      getDeviceQuery.data?.device.permissions.createBusinessUser,
    canViewBusinessUsers:
      getDeviceQuery.data?.device.permissions.viewBusinessUsers,
    canViewBusinessUserCount:
      getDeviceQuery.data?.device.permissions.viewBusinessUserCount,
  };

  const canLoadMoreUsers = users.length < usersCount;

  function handleLoadMoreUsers() {
    getBusinessQuery.fetchMore({
      variables: {
        usersSkip: users.length,
        usersLimit: pagination.PAGE_SIZE,
      },
    });
  }

  if (getDeviceQuery.loading || getBusinessQuery.loading) {
    return <CenteredSpinner />;
  }

  if (!canViewBusinessUsers || !canViewBusinessUserCount) {
    return (
      <ErrorIndicator
        heading={t('authorization:error.heading')}
        subheading={t('authorization:error.subheading')}
      />
    );
  }

  const { device: viewingDevice } = getDeviceQuery.data;

  return (
    <Stack marginBottom={16} padding={{ base: 4, md: 8 }} spacing={8}>
      <HStack>
        <BackButton />

        <Heading as="h1" size="2xl">
          {t('heading')}
        </Heading>
      </HStack>

      <InfiniteScrollList
        spacing={2}
        canLoadMore={canLoadMoreUsers}
        onLoadMore={handleLoadMoreUsers}
      >
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>{t('name')}</Th>
              <Th>{t('role')}</Th>
              <Th></Th>
            </Tr>
          </Thead>

          <Tbody>
            {users.map((user: any) => {
              const { _id, name, role } = {
                _id: user._id,
                name: [user.name.first, user.name.last].join(' '),
                role: t(`authorization:roles.short.${user.role}`),
              };

              return (
                <Tr
                  borderLeftWidth={viewingDevice.ownerId === _id ? 2 : 0}
                  borderColor="brand.300"
                  cursor="pointer"
                  key={_id}
                >
                  <Td
                    onClick={() => {
                      history.push(`/users/${_id}`);
                    }}
                  >
                    {name}
                  </Td>

                  <Td
                    onClick={() => {
                      history.push(`/users/${_id}`);
                    }}
                  >
                    {role}
                  </Td>

                  <Td>
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        variant="ghost"
                        rounded="md"
                        icon={<BiDotsVerticalRounded />}
                        aria-label="Options"
                      />

                      <MenuList>
                        <MenuItem
                          icon={<MdInfoOutline size={16} />}
                          onClick={() => {
                            history.push(`/users/${_id}#details`);
                          }}
                        >
                          {t('details')}
                        </MenuItem>

                        <MenuItem
                          icon={<FaMobileAlt size={16} />}
                          onClick={() => {
                            history.push(`/users/${_id}#devices`);
                          }}
                        >
                          {t('devices')}
                        </MenuItem>

                        <MenuItem
                          color="red"
                          icon={<IoMdTrash size={16} />}
                          onClick={() => {
                            setSelectedUserForDeletion(user);
                          }}
                        >
                          {t('delete')}
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </InfiniteScrollList>

      <UserCreationModal
        isOpen={canCreateBusinessUsers && userCreationModal.isOpen}
        onClose={userCreationModal.onClose}
      />

      <Alerter
        headerText={t('userDeletionDialog.title')}
        cancelText={t('userDeletionDialog.cancel')}
        confirmationText={t('userDeletionDialog.confirm')}
        confirmationColorScheme="red"
        onConfirmation={handleUserDeletionConfirmation}
        disclosure={{
          isOpen: selectedUserForDeletion,
          onClose: closeUserDeletionDialog,
        }}
        performingOperation={deleteBusinessUserMutation.loading}
      >
        <Translation
          t={t}
          i18nKey="userDeletionDialog.confirmation"
          values={{
            devicesCount: selectedUserForDeletion?.devicesCount,
          }}
          components={[]}
        />
      </Alerter>

      {canCreateBusinessUsers ? (
        <FAB
          colorScheme="brand"
          aria-label="Create group"
          icon={<IoAddSharp size={30} />}
          onClick={userCreationModal.onOpen}
        />
      ) : null}
    </Stack>
  );
}
