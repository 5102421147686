import { useEffect } from 'react';
import { Box, Button, ButtonProps, Text, useInterval } from '@chakra-ui/react';
import { keys, times } from '@companyon/constants';
import { realTimeBlacklist, useStorage } from '@companyon/hooks';
import { usePingMutation } from '../graphql/hooks/mutations/ping';
import { useSetDeviceBearerTokenMutation } from '../graphql/hooks/mutations/setDeviceBearerToken';
import { useGetDeviceQuery } from '../graphql/hooks/queries/getDevice';

/*
 * NOTE: Also contains device management logic
 * - Access device on DB, possibly triggering removal of outdated devices
 * - Remove deviceId from blacklist when device query successful
 * - Migrate to bearerToken
 */

interface DeviceSwitcherButtonProps extends ButtonProps {
  onDeviceSwitcherOpen: () => void;
  onMenuClose: () => void;
}

export function DeviceSwitcherButton(props: DeviceSwitcherButtonProps) {
  const { onDeviceSwitcherOpen, onMenuClose, ...rest } = props;
  const [{ deviceId, bearerToken }, { setBearerToken, removeIdFromBlacklist }] =
    useStorage();

  const getDeviceQuery = useGetDeviceQuery({
    skip: !Boolean(deviceId),
    variables: {
      _id: deviceId,
    },
  });

  const [ping] = usePingMutation();
  const [setDeviceBearerToken, deviceBearerTokenMutation] =
    useSetDeviceBearerTokenMutation({
      skip: !deviceId,
      onCompleted: (data: any) => {
        const response = data?.setDeviceBearerToken;
        if (response?.deviceId && response.bearerToken) {
          if (localStorage.getItem(keys.DEVICE_ID) === response.deviceId) {
            // Have not switched deviceId in the meantime
            setBearerToken(response.deviceId, response.bearerToken);
          }
        }
      },
    });

  useEffect(executePingMutation, [ping, deviceId]);
  useInterval(executePingMutation, times.PING_INTERVAL);

  useEffect(executeSetDeviceBearerTokenMutation, [
    setDeviceBearerToken,
    deviceBearerTokenMutation,
    setBearerToken,
    deviceId,
    bearerToken,
  ]);

  if (getDeviceQuery.data && realTimeBlacklist().includes(deviceId)) {
    removeIdFromBlacklist(deviceId);
  }

  const { deviceName, deviceColor } = {
    deviceName: getDeviceQuery.data?.device.name,
    deviceColor: getDeviceQuery.data?.device.color,
  };

  function executePingMutation() {
    if (deviceId) {
      ping();
    }
  }

  function executeSetDeviceBearerTokenMutation() {
    if (deviceId && !bearerToken) {
      if (!deviceBearerTokenMutation.loading) {
        setDeviceBearerToken();
      }
    }
  }

  if (deviceId) {
    return (
      <Button
        leftIcon={
          deviceName ? (
            <Box
              boxSize="0.75em"
              backgroundColor={deviceColor}
              rounded="full"
            />
          ) : undefined
        }
        onClick={() => {
          onDeviceSwitcherOpen();
          onMenuClose();
        }}
        {...rest}
      >
        {deviceName ? (
          <Text as="span" isTruncated={true} color={rest.color}>
            {deviceName}
          </Text>
        ) : (
          <Box boxSize="0.75em" backgroundColor={deviceColor} rounded="full" />
        )}
      </Button>
    );
  } else {
    return null;
  }
}
