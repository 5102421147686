import { useState, useMemo } from 'react';
import {
  Box,
  Stack,
  VStack,
  Heading,
  Text,
  Tag,
  IconButton,
  Skeleton,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { Alerter, BackToTop } from '@companyon/components';
import { IoBagHandle } from 'react-icons/io5';
import { FaUndo } from 'react-icons/fa';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formatCurrency, divide } from '@trifence/utilities';
import { pagination } from '@companyon/constants';
import { AnimatedCard } from '../components/AnimatedCard';
import { CountdownIconButton } from '../components/CountdownIconButton';
import { TransactionList } from '@companyon/components';
import { TransactionCreationModal } from '../components/TransactionCreationModal';
import { useGetDeviceQuery } from '../graphql/hooks/queries/getDevice';
import { useGetBusinessQuery } from '../graphql/hooks/queries/getBusiness';
import { useGetCardQuery } from '../graphql/hooks/queries/getCard';
import { useCancelTransactionMutation } from '../graphql/hooks/mutations/cancelTransaction';
import { useStorage } from '@companyon/hooks';
import { useLastTransaction } from '../hooks/useLastTransaction';
import placeholderAsset from '../assets/images/placeholder.png';
import cancelAsset from '../assets/images/cancel.svg';

type RouteParams = {
  cardId: string;
};

export function Card() {
  const { cardId } = useParams<RouteParams>();
  const [{ deviceId }] = useStorage();
  const {
    t,
    i18n: { language: locale },
  } = useTranslation(['card', 'authorization']);
  const addToast = useToast();
  const transactionCreationModal = useDisclosure();
  const transactionCancellationDialog = useDisclosure();

  const getDeviceQuery = useGetDeviceQuery({
    variables: {
      _id: deviceId,
    },
  });

  const getBusinessQuery = useGetBusinessQuery({
    skip: !getDeviceQuery.data?.device.owner.employerId,
    variables: {
      _id: getDeviceQuery.data?.device.owner.employerId ?? '',
    },
  });

  const getCardQuery = useGetCardQuery({
    variables: {
      _id: cardId,
      transactionsSkip: 0,
      transactionsLimit: pagination.PAGE_SIZE,
    },
  });

  const [cancelTransaction, cancelTransactionMutation] =
    useCancelTransactionMutation({
      onCompleted: (data: any) => {
        if (data?.cancelTransaction) {
          addToast({
            status: 'success',
            title: t('toast.transactionCancelled'),
          });
        }
      },
    });

  const lastTransaction = useLastTransaction(
    getCardQuery.data?.card?.transactions,
  );

  const [intendedOperation, setIntendedOperation] = useState<
    'DEBIT' | 'CREDIT'
  >('DEBIT');
  const [isAnimationComplete, setAnimationComplete] = useState(false);

  const {
    isLoading,
    balance,
    designUrl,
    transactions,
    transactionsCount,
    canViewCardTransactions,
    canCancelCardTransaction,
    canCreditCard,
    canDebitCard,
  } = {
    isLoading: getCardQuery.loading || getBusinessQuery.loading,
    balance: getCardQuery.data?.card.balance ?? 0,
    designUrl: getCardQuery.data?.card.designUrl,
    transactions: getCardQuery.data?.card.transactions ?? [],
    transactionsCount: getCardQuery.data?.card.transactionsCount ?? 0,
    canViewCardTransactions:
      getDeviceQuery.data?.device.permissions.viewCardTransactions,
    canCancelCardTransaction:
      getDeviceQuery.data?.device.permissions.cancelCardTransaction,
    canCreditCard: getDeviceQuery.data?.device.permissions.creditCard,
    canDebitCard: getDeviceQuery.data?.device.permissions.debitCard,
  };

  const canTransact = canCancelCardTransaction && canCreditCard && canDebitCard;
  const canLoadMoreTransactions = transactions.length < transactionsCount;

  const formattedBalance = useMemo(() => {
    return formatCurrency()(divide(balance, 100));
  }, [balance]);

  function handleLoadMoreTransactions() {
    getCardQuery.fetchMore({
      variables: {
        transactionsSkip: transactions.length,
        transactionsLimit: pagination.PAGE_SIZE,
      },
    });
  }

  async function handleTransactionCancellationConfirmation() {
    await cancelTransaction({
      variables: {
        input: {
          transactionId: lastTransaction?._id,
        },
      },
    });

    transactionCancellationDialog.onClose();
  }

  return (
    <Stack
      paddingX={{ base: 0, md: 8 }}
      paddingY={{ base: 2, md: 8 }}
      paddingBottom={16}
      spacing={8}
    >
      <Stack
        direction={{ base: 'column', md: 'row' }}
        align="center"
        spacing={4}
      >
        <Heading
          as="h1"
          color="brand.700"
          textAlign={{ base: 'center', md: 'left' }}
          size="2xl"
        >
          {t('heading')}
        </Heading>

        <Skeleton isLoaded={!isLoading} borderRadius="md">
          <Tag
            size="lg"
            fontSize="xl"
            fontWeight="bold"
            backgroundColor="brand.500"
            color="white"
          >
            {formattedBalance}
          </Tag>
        </Skeleton>
      </Stack>

      {isLoading ? null : (
        <Stack spacing={8}>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            justify="center"
            spacing={8}
          >
            <Box
              width={{
                base: '80%',
                sm: '60%',
                md: '45%',
                lg: '40%',
                xl: '30%',
              }}
              align="center"
              alignSelf="center"
            >
              <AnimatedCard src={designUrl} />
            </Box>

            {(() => {
              if (!canTransact) {
                return null;
              }

              return (
                <Stack direction={{ base: 'row', md: 'column' }} paddingX={4}>
                  <Stack
                    flex={1}
                    direction={{ base: 'column', md: 'row' }}
                    align="center"
                    spacing={{ base: 2, md: 4 }}
                  >
                    <IconButton
                      size="lg"
                      width="48px"
                      height="48px"
                      isRound={true}
                      aria-label={t('creditCard')}
                      backgroundColor="gray.500"
                      icon={<AiOutlinePlus size={24} />}
                      _hover={{
                        backgroundColor: 'gray.600',
                      }}
                      onClick={() => {
                        setIntendedOperation('CREDIT');
                        transactionCreationModal.onOpen();
                      }}
                    />

                    <Text fontSize="lg" textAlign="center">
                      {t('creditCard')}
                    </Text>
                  </Stack>

                  <Stack
                    flex={1}
                    direction={{ base: 'column', md: 'row' }}
                    align="center"
                    spacing={{ base: 2, md: 4 }}
                  >
                    <IconButton
                      size="lg"
                      width="48px"
                      height="48px"
                      isRound={true}
                      aria-label={t('debitCard')}
                      colorScheme="brand"
                      icon={<AiOutlineMinus size={24} />}
                      onClick={() => {
                        setIntendedOperation('DEBIT');
                        transactionCreationModal.onOpen();
                      }}
                    />

                    <Text fontSize="lg" textAlign="center">
                      {t('debitCard')}
                    </Text>
                  </Stack>

                  {isAnimationComplete ||
                  !lastTransaction.isCancellable ? null : (
                    <Stack
                      flex={1}
                      direction={{ base: 'column', md: 'row' }}
                      align="center"
                      spacing={{ base: 2, md: 4 }}
                    >
                      <CountdownIconButton
                        id={lastTransaction._id}
                        initialProgress={
                          1 - lastTransaction.cancellationExpiresAfter / 60
                        }
                        duration={60}
                        size="lg"
                        isRound={true}
                        aria-label={t('cancelTransaction')}
                        colorScheme="red"
                        icon={<FaUndo size={18} />}
                        onClick={() => {
                          transactionCancellationDialog.onOpen();
                        }}
                        onAnimationComplete={() => {
                          setAnimationComplete(true);
                        }}
                      />

                      <Text fontSize="lg" textAlign="center">
                        {t('cancelTransaction')}
                      </Text>
                    </Stack>
                  )}
                </Stack>
              );
            })()}
          </Stack>

          <Stack>
            {(() => {
              if (!canViewCardTransactions) {
                return null;
              }

              if (transactions.length === 0) {
                return (
                  <VStack padding={{ base: 4, md: 8 }} spacing={6}>
                    <IoBagHandle size={64} />

                    <Heading
                      as="h1"
                      color="brand.700"
                      textAlign="center"
                      fontSize={{ base: 'xl', md: '2xl' }}
                    >
                      {t('noTransactions')}
                    </Heading>
                  </VStack>
                );
              }

              return (
                <Stack paddingX={{ base: 4, md: 0 }} spacing={8}>
                  <TransactionList
                    perspective="CARD"
                    transactions={transactions}
                    locale={locale}
                    canLoadMore={canLoadMoreTransactions}
                    onLoadMore={handleLoadMoreTransactions}
                    cancelAsset={cancelAsset}
                    placeholderAsset={placeholderAsset}
                  />

                  {canLoadMoreTransactions ? null : (
                    <BackToTop alignSelf="center">{t('backToTop')}</BackToTop>
                  )}
                </Stack>
              );
            })()}
          </Stack>
        </Stack>
      )}

      {transactionCreationModal.isOpen ? (
        <TransactionCreationModal
          device={getDeviceQuery.data.device}
          business={getBusinessQuery.data.business}
          card={getCardQuery.data.card}
          intendedOperation={intendedOperation}
          onClose={transactionCreationModal.onClose}
        />
      ) : null}

      <Alerter
        headerText={t('transactionCancellationDialog.title')}
        cancelText={t('transactionCancellationDialog.cancel')}
        confirmationText={t('transactionCancellationDialog.confirm')}
        confirmationColorScheme="red"
        onConfirmation={handleTransactionCancellationConfirmation}
        disclosure={transactionCancellationDialog}
        performingOperation={cancelTransactionMutation.loading}
      >
        {t('transactionCancellationDialog.confirmation')}
      </Alerter>
    </Stack>
  );
}
